function ResultFilter() {
    return (<>
    <section id="fillter_section_start" class="new_hotels_page_start">
        	<div class="container">
        		<div class="col-md-12">
        			<div class="row">
        				<div class="col-md-2 col-12">
        					<div class="multiselect">
        						<div class="hotel_icons">
        							<span class="hotel-icon"><i class="fa fa-bed"></i><b>Type</b></span></div>
        							<div class="button-group">
							        <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown">All Hotels <span class="caret"></span></button>
									<ul class="dropdown-menu">
									  <li><a href="#" class="small" data-value="option1" tabIndex="-1"><input type="checkbox"/>&nbsp;All Hotels (1744)</a></li>
									  <li><a href="#" class="small" data-value="option2" tabIndex="-1"><input type="checkbox"/>&nbsp;BUSINESS HOTEL (66)</a></li>
									  <li><a href="#" class="small" data-value="option3" tabIndex="-1"><input type="checkbox"/>&nbsp;RESORT (8)</a></li>
									  <li><a href="#" class="small" data-value="option4" tabIndex="-1"><input type="checkbox"/>&nbsp;HOME STAY (6)</a></li>
									  <li><a href="#" class="small" data-value="option5" tabIndex="-1"><input type="checkbox"/>&nbsp;SERVICE APARTMENT (58)</a></li>
									  <li><a href="#" class="small" data-value="option6" tabIndex="-1"><input type="checkbox"/>&nbsp;CITY HOTEL (728)</a></li>
									</ul>
									  </div>  
							   </div>
        				</div>
        				<div class="col-md-2">
        					<div class="multiselect">
        						<div class="hotel_icons">
        							<span class="hotel-icon"><i class="fa fa-inr"></i><b>Price (INR)</b></span></div>
        							<div class="button-group">
							        <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown">141,22,000 <span class="caret"></span></button>
									<ul class="dropdown-menu">
									  	<li>
										  	<div class="price-range-slider">  
	  										  <p class="range-value">
	    									  <input type="text" id="amount" readonly/></p>
	  										  <div id="slider-range" class="range-bar"></div>  
											</div>
									  	</li>
									</ul>
									  </div>  
							   </div>
        				</div>
        				<div class="col-md-2">
        					<div class="multiselect">
        						<div class="hotel_icons">
        							<span class="hotel-icon"><i class="fa fa-inr"></i><b>Price (INR)</b></span></div>
        							<div class="button-group">
							        <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown">All Starts <span class="caret"></span></button>
									<ul class="dropdown-menu">
									  <li><a href="#" class="small" data-value="option1" tabIndex="-1"><input type="checkbox"/>&nbsp;All Stars <span class="js-allstar">(1744)</span></a></li>
									  <li><a href="#" class="small" data-value="option2" tabIndex="-1"><input type="checkbox"/><i class="fa fa-star"></i> &nbsp;(333)</a></li>
									  <li><a href="#" class="small" data-value="option3" tabIndex="-1"><input type="checkbox"/><i class="fa fa-star"></i><i class="fa fa-star"></i> &nbsp;(303)</a></li>
									  <li><a href="#" class="small" data-value="option4" tabIndex="-1"><input type="checkbox"/><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i> &nbsp;(105)</a></li>
									  <li><a href="#" class="small" data-value="option5" tabIndex="-1"><input type="checkbox"/><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>&nbsp;(201)</a></li>
									  <li><a href="#" class="small" data-value="option6" tabIndex="-1"><input type="checkbox"/><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>&nbsp;(654)</a></li>
									</ul>
									  </div>  
							   </div>
        				</div>
        				<div class="col-md-2">
        					<div class="multiselect">
        						<div class="hotel_icons">
        							<span class="hotel-icon"><i class="fa fa-pie-chart"></i><b>Amenities </b></span></div>
        							<div class="button-group">
							        <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown">All Amenities <span class="caret"></span></button>
									<ul class="dropdown-menu">
									  <li><a href="#" class="small" data-value="option1" tabIndex="-1"><input type="checkbox"/>&nbsp;Airport/Station Transfer (317)</a></li>
									  <li><a href="#" class="small" data-value="option2" tabIndex="-1"><input type="checkbox"/>&nbsp;ATM (7)</a></li>
									  <li><a href="#" class="small" data-value="option3" tabIndex="-1"><input type="checkbox"/>&nbsp;Audiovisual Equipment (13)</a></li>
									  <li><a href="#" class="small" data-value="option4" tabIndex="-1"><input type="checkbox"/>&nbsp;Ayurveda Center (18)</a></li>
									  <li><a href="#" class="small" data-value="option5" tabIndex="-1"><input type="checkbox"/>&nbsp;Babysitting (2)</a></li>
									  <li><a href="#" class="small" data-value="option6" tabIndex="-1"><input type="checkbox"/>&nbsp;Ballroom (121)</a></li>
									</ul>
									  </div>  
							   </div>
        				</div>
        				<div class="col-md-2">
        					<div class="multiselect">
        						<div class="hotel_icons">
        							<span class="hotel-icon"><i class="fa fa-thumb-tack"></i><b>Locations</b></span></div>
        							<div class="button-group">
							        <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown">All locations <span class="caret"></span></button>
									<ul class="dropdown-menu">
									  <li><a href="#" class="small" data-value="option1" tabIndex="-1"><input type="checkbox"/>&nbsp;Adchini</a></li>
									  <li><a href="#" class="small" data-value="option2" tabIndex="-1"><input type="checkbox"/>&nbsp;Aerocity</a></li>
									  <li><a href="#" class="small" data-value="option3" tabIndex="-1"><input type="checkbox"/>&nbsp;Ajmal Khan Road</a></li>
									  <li><a href="#" class="small" data-value="option4" tabIndex="-1"><input type="checkbox"/>&nbsp;Alipur</a></li>
									  <li><a href="#" class="small" data-value="option5" tabIndex="-1"><input type="checkbox"/>&nbsp;All India Institute of Medical Sciences</a></li>
									  <li><a href="#" class="small" data-value="option6" tabIndex="-1"><input type="checkbox"/>&nbsp;Microsoft.IndexingOptions</a></li>
									</ul>
									  </div>  
							   </div>
        				</div>
        				<div class="col-md-2">
        					<div class="input-group searching_tab">
					            <input type="text" class="form-control" placeholder="Search" name="q"/>
					            <div class="input-group-btn">
					                <button class="btn btn-default" type="submit"><i class="glyphicon glyphicon-search"></i></button>
					            </div>
					        </div>
        				</div>
        			</div>
				</div>
			</div>
		</section>
    </>);
}

export default ResultFilter;