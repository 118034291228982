import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { Chip } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
function HotelBook() {
    const [gst,setGst] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (<>
    <Container>
    <Row className="gx-0">
            <Col sm={9}>
            <div className="confirmSection">
        <h2>Review your Booking</h2>
        <div className="idDate">
            <p><span>Booking ID:</span> MF24LS13V1SD32V1S3DV1</p>
            <p><span>Booking On:</span> 09-Nov-2023</p>
        </div>
    </div>
    <div className="ticketInfo">
        <div className="wayto" style={{display:"flex"}}>
        <div style={{width:"80%"}}>
        <Chip label="Couple Friendly" />
            <h2 className="mt-3">Ayodhya Place Beach Resort </h2>
            <p style={{fontSize:"12px",fontWeight:"600"}}>112/2, Moo 3, Ao-Nang Amphur Muang Krabi 81000, Thailand</p>
            <p style={{fontSize:"12px",fontWeight:"600",color:"#000"}}><b>Contect:</b> +66 88 765 6869</p>
        </div>
        <div style={{width:"20%"}}><img className="hotelImage" src="https://www.tajhotels.com/content/dam/luxury/hotels/Taj_Mahal_Delhi/images/gallery/rooms-suites/images_2021/taj-mahal-photo-new.jpg/jcr:content/renditions/cq5dam.web.1280.1280.jpeg"/></div>
        </div>
        <hr/>
        <div className="ticketContent">
         
         <div className="flightDateTime">
            <div className="airportName"><p><b>CHECK - IN {"(Krabi Time)"}</b></p></div>
            <div className="flightTimes mt-3"><p style={{display:"flex",justifyContent:"center",alignItems:"center",}}><b style={{fontSize:"50px",color:"#000",marginRight:"15px"}}> 12 </b><span>Nov, 2023<br/>Sun, 05:55</span></p></div>
         </div>
         <div className="stop" style={{display:"flex",justifyContent:"center",alignItems:"center"}}><p><b>2 Night Stay</b></p><br/><p></p></div>
         <div className="flightDateTime"> 
            <div className="airportName"><p><b>CHECK - OUT {"(Krabi Time)"}</b></p></div>
            <div className="flightTimes mt-3"><p style={{display:"flex",justifyContent:"center",alignItems:"center",}}><b style={{fontSize:"50px",color:"#000",marginRight:"15px"}}> 12 </b><span>Nov, 2023<br/>Sun, 05:55</span></p></div>         </div>
         <div style={{width:"20%"}}><img className="hotelImage" src="https://www.google.com/maps/d/thumbnail?mid=1YzngLKIYrfFajmxwAE3xU0RyMxw&hl=en_US"/></div>
      </div>
      <hr/>
      <div className="traveller">
        <h5><b style={{color:"#000"}}>Luxe Twin Room</b></h5>
        <p>2 Adults</p>
        <ul style={{listStyle:"inside",color:"#4a4a4a"}}>
            <li>Room Only</li>
            <li>No meals included</li>
            <li>Free Welcome Drink on Arrival</li>
        </ul>
      
      </div>
    </div>
    <div className="gustDetail">
    <div class="details_information">
    							<h3>Travel Details</h3>
    							     <div class="pay-sign-box">
                                        <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <h4>Adult</h4>
                                        <div class="row">
                                            <div class="form-group col-md-2">
                                                <label>Adult1 *</label>
                                                <select class="form-control">
                                                    <option value="93" data-country-iso="afg" data-country-name="Afghanistan">
                                                        Mr.
                                                    </option>
                                                    <option value="355" data-country-iso="alb" data-country-name="Albania">
                                                        Mrs
                                                    </option>
                                                    <option value="213" data-country-iso="dza" data-country-name="Algeria">
                                                        Ms
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>First Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>Last Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                        </div>
                                        <div className="row">
                                        <div class="form-group col-md-6">
                                                <label>Email *</label>
                                                <input class="form-control" type="text"/>
                                            </div> 
                                            <div class="form-group col-md-6">
                                                <label>Phone *</label>
                                                <input class="form-control" type="text"/>
                                            </div> 
                                        </div>
                                        <div className="row">
                                        <div class="form-group col-md-12">
                                        <Form>
      <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label="Enter GST Details (Optional)"
        value={gst}
        onChange={(e)=>setGst(e.target.checked)}
      />
    </Form>
   {
    gst ? 
    <div className="row">
    <div class="form-group col-md-4">
                                                <label>Registration Number *</label>
                                                <input class="form-control" type="text"/>
                                            </div> 
                                            <div class="form-group col-md-4">
                                                <label>Registered Company name *</label>
                                                <input class="form-control" type="text"/>
                                            </div> 
                                            <div class="form-group col-md-4">
                                                <label>Registered Company address *</label>
                                                <input class="form-control" type="text"/>
                                            </div> 
    </div>
    : null
   }
   <button type="submit" class="btn text-center btn-danger book round center-block mt-3" onClick={handleShow}>Add Guest</button>
   <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Guest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="row">
                                            <div class="form-group col-md-2">
                                                <label>Adult1 *</label>
                                                <select class="form-control">
                                                    <option value="93" data-country-iso="afg" data-country-name="Afghanistan">
                                                        Mr.
                                                    </option>
                                                    <option value="355" data-country-iso="alb" data-country-name="Albania">
                                                        Mrs
                                                    </option>
                                                    <option value="213" data-country-iso="dza" data-country-name="Algeria">
                                                        Ms
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>First Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>Last Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                            <Form>
                                            <Form.Check // prettier-ignore
        type="checkbox"
        id="custom-switch"
        label="Below 12 years of age"
        value={gst}
      />
    </Form>
                                        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Add</Button>
        </Modal.Footer>
      </Modal>
      <Table className="mb-0 mt-3"  striped bordered hover>
        <tr>
            <th>Sr. No.</th>
            <th>Guest Name</th>
            <th>Type</th>
            
        </tr>
        <tr>
            <td>1</td>
            <td>Mr Aditya Narain Mukherjee</td>
            <td>Adult</td>
            
        </tr>
      </Table>

                                            </div>
                                        </div>
                                     </div>
                                     
                                     
    </div>
    </div>
            </Col>
            <Col sm={3}>
                <div className="sidePanal">
                    <h3 className="sidePanalHeading">Price Breakup</h3>
                    <p className="optionlist"><span className="icon" style={{width:"50%",lineHeight:"120%"}}>1 Room x 1 Night<br/><span style={{color:"#808080",fontSize:"12px"}}>Base Price</span> </span><span className="textLine"  style={{width:"50%",textAlign:'right'}}>₹ 6,499</span></p>
                    <hr/>
                    <p className="optionlist"><span className="icon" style={{width:"50%",lineHeight:"120%"}}>Hotel Taxes </span><span className="textLine"  style={{width:"50%",textAlign:'right'}}>₹ 740</span></p>
                    <hr/>
                    <p className="optionlist"><span className="icon" style={{width:"50%",lineHeight:"120%",fontWeight:"bold",color:"#000",fontSize:"18px"}}>Total Amount</span><span className="textLine"  style={{width:"50%",textAlign:'right',fontWeight:"bold",color:"#000",fontSize:"18px"}}>₹ 7,279</span></p>
                   
                     </div>
            </Col>
        </Row>
    </Container>
    
    </>);
}
export default HotelBook;