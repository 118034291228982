import { Col, Container, Modal, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import "./hotelStyle.css";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Button from "react-bootstrap/Button";
import { useState } from "react";
function HotelView() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Container className="mt-5">
        <Row>
          <Col md={8}>
            <Carousel>
              <Carousel.Item>
                <img
                  className="hotelimage"
                  alt="First slide"
                  src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"
                />
                <Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="hotelimage"
                  alt="First slide"
                  src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"
                />
                <Carousel.Caption>
                  <h3>Second slide label</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="hotelimage"
                  alt="First slide"
                  src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"
                />
                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl
                    consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
            <div className="iconList">
              <div>
                <img
                  src="//promos.makemytrip.com/images/highlighted/kids_play_area.png"
                  alt=""
                  title=""
                />
                <p>Kids Play Area</p>
              </div>
              <div>
                <img
                  src="//promos.makemytrip.com/images/highlighted/kids_play_area.png"
                  alt=""
                  title=""
                />
                <p>Indoor Games</p>
              </div>
              <div>
                <img
                  src="//promos.makemytrip.com/images/highlighted/restaurant.png"
                  alt=""
                  title=""
                />
                <p>Restaurant</p>
              </div>
              <div>
                <img
                  src="//promos.makemytrip.com/images/highlighted/bar.png"
                  alt=""
                  title=""
                />
                <p>Bar</p>
              </div>
              <div>
                <img
                  src="//promos.makemytrip.com/images/highlighted/vehicle_rentals.png"
                  alt=""
                  title=""
                />
                <p>Kids' Meals</p>
              </div>
              <div>
                <img
                  src="//promos.makemytrip.com/images/highlighted/kids_play_area.png"
                  alt=""
                  title=""
                />
                <p>Kids Play Area</p>
              </div>
              <div>
                <img
                  src="//promos.makemytrip.com/images/highlighted/kids_play_area.png"
                  alt=""
                  title=""
                />
                <p>Kids Play Area</p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <h2 className="hotelName">Ginger Goa, Panjim</h2>
            <div className="review">
              <StarIcon />
              <StarIcon />
              <StarHalfIcon />
              <StarOutlineIcon />
              <StarOutlineIcon />
            </div>
            <p className="hoteladdress">
              <LocationOnIcon /> Panjim, Goa | 1.6 km from Immaculate Conception
              Church
            </p>
            <div className="hotlePrice">
              <div className="priceHead">Luxe Twin Room</div>
              <p className="priceAmount">
                ₹ 7,999 /<span>Per Night</span>
              </p>
              <p className="priceTex">+ ₹ 1,440 taxes & fees</p>
            </div>
            <iframe
              className="mt-3"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224644.7778602098!2d77.17610088444373!3d28.386813061493516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdc1827a8884d%3A0xd0a87c76076637f4!2sTaj%20Hotel!5e0!3m2!1sen!2sin!4v1700587953085!5m2!1sen!2sin"
              width="100%"
              height="219"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Tabs value={value} onChange={handleChange} centered>
                <Tab label="OVERVIEW" />
                <Tab label="Room" />
                <Tab label="Location" />
                <Tab label="PROPERTY RULES" />
                <Tab label="USER REVIEWS" />
                <Tab label="SIMILAR PROPERTIES" />
              </Tabs>
            </Box>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h2 style={{ color: "#000", fontWeight: "bold" }}>
              About Hotel Regent Laguna
            </h2>
            <p>
              <b>
                Indulge in a peaceful stay at this charming hotel in Goa,
                featuring a pool, restaurant, bar, sit-outs & free Wi-Fi.
              </b>
            </p>
            <ul style={{ listStyle: "inside", marginTop: "5px" }}>
              <li>Take a dip in the hotel’s expansive swimming pool. </li>
              <li>
                Guests can visit the famous Anjuna Beach, just 10-minutes from
                the property.{" "}
              </li>
              <li>
                Relish the exquisite global cuisines and buffet menus served at
                the in-house restaurant.{" "}
              </li>
              <li>Enjoy a relaxing body massage at the in-house spa. </li>
            </ul>
            <h2 className="mt-3" style={{ color: "#000", fontWeight: "bold" }}>
              Select Rooms
            </h2>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={<Checkbox />}
                  label="Breakfast Included"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={<Checkbox />}
                  label="Breakfast & Lunch/Dinner Included"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="hotelpricetable">
              <div className="hotelpriceHeader">
                <div className="roomType">Room Type</div>
                <div className="roomOptions">Options</div>
                <div className="roomPrice">Price</div>
              </div>
              <div className="roomdetail">
                <div className="roomleft">
                  <img
                    src="https://r1imghtlak.mmtcdn.com/52a37364022111ea896a0242ac110002.jpg?downsize=377:200&amp;crop=377:200"
                    alt=""
                    title=""
                    onClick={handleShow}
                  />
                  
                  <h3
                    className="mt-3"
                    style={{ color: "#000", fontWeight: "bold" }}
                  >
                    Deluxe Room
                  </h3>
                  <ul className="rmTypeList appendTop10 vertical">
                    <li className="rmTypeList__item">
                      <span className="rmTypeList__item--icon appendRight10">
                        <img
                          src="https://promos.makemytrip.com/Hotels_product/Hotel_SR/Android/drawable-hdpi/view.png"
                          alt="221 sq.ft"
                        />
                      </span>
                      <span className="makeFlex column column-text">
                        <span className="rmTypeList__item--text">
                          221 sq.ft
                        </span>
                      </span>
                    </li>
                    <li className="rmTypeList__item">
                      <span className="rmTypeList__item--icon appendRight10">
                        <img
                          src="https://promos.makemytrip.com/Hotels_product/Hotel_SR/Android/drawable-hdpi/view.png"
                          alt="Garden View"
                        />
                      </span>
                      <span className="makeFlex column column-text">
                        <span className="rmTypeList__item--text">
                          Garden View
                        </span>
                      </span>
                    </li>
                    <li className="rmTypeList__item">
                      <span className="rmTypeList__item--icon appendRight10">
                        <img
                          src="https://promos.makemytrip.com/Hotels_product/Hotel_SR/Android/drawable-hdpi/bed.png"
                          alt="King Bed"
                        />
                      </span>
                      <span className="makeFlex column column-text">
                        <span className="rmTypeList__item--text">King Bed</span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="roomRight">
                  <div className="roomRightRow">
                    <div className="roomRightoption">
                      <Chip label="RECOMMENDED" color="success" />
                      <h4
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                      >
                        Room Only
                      </h4>
                      <ul style={{ listStyle: "inside", marginTop: "5px" }}>
                        <li>Non-Refundable</li>
                        <li>No meals included</li>
                      </ul>
                    </div>
                    <div className="roomRightprice">
                      <div className="priceHead">Luxe Twin Room</div>
                      <p className="priceAmount">
                        ₹ 7,999 /<span>Per Night</span>
                      </p>
                      <p className="priceTex">+ ₹ 1,440 taxes & fees</p>
                      <button
                        type="submit"
                        class="btn text-center btn-danger book round center-block mt-3"
                      >
                        Select Rooms
                      </button>
                    </div>
                  </div>
                  <div className="roomRightRow">
                    <div className="roomRightoption">
                      <Chip label="RECOMMENDED" color="success" />
                      <h4
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                      >
                        Room Only
                      </h4>
                      <ul style={{ listStyle: "inside", marginTop: "5px" }}>
                        <li>Non-Refundable</li>
                        <li>No meals included</li>
                      </ul>
                    </div>
                    <div className="roomRightprice">
                      <div className="priceHead">Luxe Twin Room</div>
                      <p className="priceAmount">
                        ₹ 7,999 /<span>Per Night</span>
                      </p>
                      <p className="priceTex">+ ₹ 1,440 taxes & fees</p>
                      <button
                        type="submit"
                        class="btn text-center btn-danger book round center-block mt-3"
                      >
                        Select Rooms
                      </button>
                    </div>
                  </div>
                  <div className="roomRightRow">
                    <div className="roomRightoption">
                      <Chip label="RECOMMENDED" color="success" />
                      <h4
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                      >
                        Room Only
                      </h4>
                      <ul style={{ listStyle: "inside", marginTop: "5px" }}>
                        <li>Non-Refundable</li>
                        <li>No meals included</li>
                      </ul>
                    </div>
                    <div className="roomRightprice">
                      <div className="priceHead">Luxe Twin Room</div>
                      <p className="priceAmount">
                        ₹ 7,999 /<span>Per Night</span>
                      </p>
                      <p className="priceTex">+ ₹ 1,440 taxes & fees</p>
                      <button
                        type="submit"
                        class="btn text-center btn-danger book round center-block mt-3"
                      >
                        Select Rooms
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* __________________________________________________________________ */}
      <Modal
                    show={show}
                    size="xl"
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Luxe Twin Room</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Carousel>
                        <Carousel.Item>
                          <img
                            className="hotelimage"
                            alt="First slide"
                            src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"
                          />
                          <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>
                              Nulla vitae elit libero, a pharetra augue mollis
                              interdum.
                            </p>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="hotelimage"
                            alt="First slide"
                            src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"
                          />
                          <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.
                            </p>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="hotelimage"
                            alt="First slide"
                            src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"
                          />
                          <Carousel.Caption>
                            <h3>Third slide label</h3>
                            <p>
                              Praesent commodo cursus magna, vel scelerisque
                              nisl consectetur.
                            </p>
                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                      <ul className="rmTypeList appendTop10 vertical models">
                    <li className="rmTypeList__item">
                      <span className="rmTypeList__item--icon appendRight10">
                        <img
                          src="https://promos.makemytrip.com/Hotels_product/Hotel_SR/Android/drawable-hdpi/view.png"
                          alt="221 sq.ft"
                        />
                      </span>
                      <span className="makeFlex column column-text">
                        <span className="rmTypeList__item--text">
                          221 sq.ft
                        </span>
                      </span>
                    </li>
                    <li className="rmTypeList__item">
                      <span className="rmTypeList__item--icon appendRight10">
                        <img
                          src="https://promos.makemytrip.com/Hotels_product/Hotel_SR/Android/drawable-hdpi/view.png"
                          alt="Garden View"
                        />
                      </span>
                      <span className="makeFlex column column-text">
                        <span className="rmTypeList__item--text">
                          Garden View
                        </span>
                      </span>
                    </li>
                    <li className="rmTypeList__item">
                      <span className="rmTypeList__item--icon appendRight10">
                        <img
                          src="https://promos.makemytrip.com/Hotels_product/Hotel_SR/Android/drawable-hdpi/bed.png"
                          alt="King Bed"
                        />
                      </span>
                      <span className="makeFlex column column-text">
                        <span className="rmTypeList__item--text">King Bed</span>
                      </span>
                    </li>
                  </ul>
                  <hr/>
                  <h3 className="modelsubhead">Room Amenities</h3>
                  <ul className="pointlist">
                    <li>Work Desk</li>
                    <li>Work Desk</li>
                    <li>Work Desk</li>
                  </ul>
                  <h3 className="modelsubhead mt-3">Popular with Guests</h3>
                  <ul className="pointlist">
                    <li>Free Wi-Fi</li>
                    <li>Interconnected Room</li>
                    <li>24-hour Room Service</li>
                    <li>Bathroom</li>
                    <li>24-hour In-room Dining</li>
                  </ul>
                  <h3 className="modelsubhead mt-3">Room Features</h3>
                  <ul className="pointlist">
                    <li>Charging Points</li>
                    <li>Closet</li>
                    <li>Minibar</li>
                    <li>Seating Area</li>
                    <li>Mini Fridge</li>
                  </ul>
                  <h3 className="modelsubhead mt-3">Childcare</h3>
                  <ul className="pointlist">
                    <li>Cribs</li>
                  </ul>
                  <h3 className="modelsubhead mt-3">Beds and Blanket</h3>
                  <ul className="pointlist">
                    <li>Cushions</li>
                    <li>Pillows</li>
                    <li>Woollen Blanket</li>
                  </ul>
                  <h3 className="modelsubhead mt-3">Safety and Security</h3>
                  <ul className="pointlist">
                    <li>Cupboards with Locks</li>
                    <li>Electronic Safe</li>
                    <li>Safety</li>
                    <li>Smoke Alarm</li>
                  </ul>
                  <h3 className="modelsubhead mt-3">Media and Entertainment</h3>
                  <ul className="pointlist">
                    <li>In-house Movies</li>
                    <li>TV</li>
                  </ul>
                  <h3 className="modelsubhead mt-3">Bathroom</h3>
                  <ul className="pointlist">
                    <li>Shaving Mirror</li>
                    <li>Hairdryer</li>
                    <li>Dental Kit</li>
                    <li>Towels</li>
                    <li>Shower Cap</li>
                  </ul>
                  <h3 className="modelsubhead mt-3">Other Facilities</h3>
                  <ul className="pointlist">
                    <li>Shoe Shine</li>
                    <li>Sound Proofing</li>
                    <li>Shoe Horn</li>
                    <li>Newspaper</li>
                    <li>Newspaper</li>
                    <li>Ceiling Fan</li>
                  </ul>
                  <h3 className="modelsubhead mt-3">What Guests Said</h3>
                  <ul className="chip">
                    <li><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#24b5a8"}}></i> Service Quality</li>
                    <li><i class="fa fa-check-circle-o" aria-hidden="true" style={{color:"#000"}}></i> Room Quality</li>
                    <li><i class="fa fa-times-circle" aria-hidden="true" style={{color:"#ec252b"}}></i> Space in Rooms</li>
                    <li><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#24b5a8"}}></i> Room Cleanliness</li>
                    <li><i class="fa fa-check-circle-o" aria-hidden="true" style={{color:"#000"}}></i> Solo</li>
                    <li><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#24b5a8"}}></i> Food Cleanliness</li>
                    <li><i class="fa fa-check-circle-o" aria-hidden="true" style={{color:"#000"}}></i> Couples</li>
                    <li><i class="fa fa-times-circle" aria-hidden="true" style={{color:"#ec252b"}}></i> Bed Quality</li>
                  </ul>
                  <div className="testimonial mt-3">
                    <div className="testimonialList">
                      <div className="testHead">
                        <div className="testName">
                          <h4>Terrible Stay</h4>
                          <p>Venkatesh murthy km . Oct 22, 2023</p>
                        </div>
                        <div className="testpoint">
                          <span>1.0</span>
                        </div>
                      </div>
                      <div className="testContent">
                      Ginger hotel! my experience with ginger! my train ticket not confirmed so i booked the bus which is available so i traveled from banglore to goa panjim as per plan i suppose to reach by 20th 11. 20am but reach 8. 40 little early but i booked hotel from 20th afternoon onwards, when i went to reception she quietly denied that there is no availability wait till afternoon same condition? i was shocked when i sat and think and came back and asked them if i needed to pay i am ready they said double occupancy is there you booked a twin so it can't be changed than i paid extra for early check in! when i checked in room it was too o small bed we are two people on small bed difficult so i decided to ask because i booked twin bed from afternoon they again quietly denied, one funny thing is that when i paid extra for early check in they said we can't take the breakfast? they weren't told when i paid but from my 2 days booking it included breakfast! so dear friends i have travel-led almost all indian cities i never got this experience be careful when you choose the hotel's! i will inform my management to not to select like this no consumer centric or non respectful hotel's. Stay away from this hotel this is my suggestion. !
                      </div>
                    </div>
                    <div className="testimonialList">
                      <div className="testHead">
                        <div className="testName">
                          <h4>Terrible Stay</h4>
                          <p>Venkatesh murthy km . Oct 22, 2023</p>
                        </div>
                        <div className="testpoint">
                          <span>1.0</span>
                        </div>
                      </div>
                      <div className="testContent">
                      Ginger hotel! my experience with ginger! my train ticket not confirmed so i booked the bus which is available so i traveled from banglore to goa panjim as per plan i suppose to reach by 20th 11. 20am but reach 8. 40 little early but i booked hotel from 20th afternoon onwards, when i went to reception she quietly denied that there is no availability wait till afternoon same condition? i was shocked when i sat and think and came back and asked them if i needed to pay i am ready they said double occupancy is there you booked a twin so it can't be changed than i paid extra for early check in! when i checked in room it was too o small bed we are two people on small bed difficult so i decided to ask because i booked twin bed from afternoon they again quietly denied, one funny thing is that when i paid extra for early check in they said we can't take the breakfast? they weren't told when i paid but from my 2 days booking it included breakfast! so dear friends i have travel-led almost all indian cities i never got this experience be careful when you choose the hotel's! i will inform my management to not to select like this no consumer centric or non respectful hotel's. Stay away from this hotel this is my suggestion. !
                      </div>
                    </div>
                    <div className="testimonialList">
                      <div className="testHead">
                        <div className="testName">
                          <h4>Terrible Stay</h4>
                          <p>Venkatesh murthy km . Oct 22, 2023</p>
                        </div>
                        <div className="testpoint">
                          <span>1.0</span>
                        </div>
                      </div>
                      <div className="testContent">
                      Ginger hotel! my experience with ginger! my train ticket not confirmed so i booked the bus which is available so i traveled from banglore to goa panjim as per plan i suppose to reach by 20th 11. 20am but reach 8. 40 little early but i booked hotel from 20th afternoon onwards, when i went to reception she quietly denied that there is no availability wait till afternoon same condition? i was shocked when i sat and think and came back and asked them if i needed to pay i am ready they said double occupancy is there you booked a twin so it can't be changed than i paid extra for early check in! when i checked in room it was too o small bed we are two people on small bed difficult so i decided to ask because i booked twin bed from afternoon they again quietly denied, one funny thing is that when i paid extra for early check in they said we can't take the breakfast? they weren't told when i paid but from my 2 days booking it included breakfast! so dear friends i have travel-led almost all indian cities i never got this experience be careful when you choose the hotel's! i will inform my management to not to select like this no consumer centric or non respectful hotel's. Stay away from this hotel this is my suggestion. !
                      </div>
                    </div>
                  </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary">Understood</Button>
                    </Modal.Footer>
                  </Modal>
    </>
  );
}

export default HotelView;
