function ResultFilter() {
    return (<>
    <section id="fillter_section_start">
        	<div class="container">
        		<div class="col-md-12">
        			<div class="main_fillter">
        				<div class="main_bew_fillter">
        					<h4><span>Stops</span><span><a href="#">Reset</a></span></h4>
					        <ul>
							  <li><input type="checkbox"/><label>Non Stop</label></li>
							  <li><input type="checkbox"/><label>1 Stop</label></li>
							  <li><input type="checkbox"/><label>2 Stop</label></li>
							</ul>
						</div>
						<div class="main_bew_fillter">
        					<h4><span>Departure from New Delhi</span><span><a href="#">Reset</a></span></h4>
					        <ul>
							  <li><input type="checkbox"/><label>Before 6AM</label></li>
							  <li><input type="checkbox"/><label>6AM - 12 Noon</label></li>
							  <li><input type="checkbox"/><label>12 Noon - 6PM</label></li>
							  <li><input type="checkbox"/><label>After 6PM</label></li>
							</ul>
						</div>
						<div class="main_bew_fillter">
        					<h4><span>Arrival at Bengaluru</span><span><a href="#">Reset</a></span></h4>
					        <ul>
							  <li><input type="checkbox"/><label>Before 6AM</label></li>
							  <li><input type="checkbox"/><label>6AM - 12 Noon</label></li>
							  <li><input type="checkbox"/><label>12 Noon - 6PM</label></li>
							  <li><input type="checkbox"/><label>After 6PM</label></li>
							</ul>
						</div>
						<div class="main_bew_fillter">
        					<h4><span>Airlines</span><span><a href="#">+2 airlines</a></span></h4>
					        <ul>
							  <li><input type="checkbox"/><label>Air India (23) Rs 11,449</label></li>
							  <li><input type="checkbox"/><label>AirAsia  (3)  Rs 11,843</label></li>
							  <li><input type="checkbox"/><label>Go Air  (8)  Rs 11,111</label></li>
							  <li><input type="checkbox"/><label>IndiGo  (21)  Rs 11,007</label></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
    </>);
}

export default ResultFilter;