import './style.css'
function PackageList() {
    return (<>
    <section className="explor_room_area">
            <div className="container">
                <div className="explor_title row m0">
                    <div className="title-text">
                        <div className="left_ex_title"> 
                            <h2>Popular <span>Destinations</span></h2>
                        </div>
                    </div>
                </div>

                <div className="row explor_room_item_inner">
                    <div className="col-md-3 col-sm-6 col-6">
                        <div className="explor_item">
                            <a href="room-details.html" className="room_image">
                                <img src="img/room/1.jpg" alt=""/>
                            </a>
                            <div className="explor_text">
                                <a href="room-details.html"><h4>PREMIUM KASHMIR</h4></a>
                                <ul>
                                    <li><a href="#">Starting From:</a></li>
                                </ul>
                                <div className="explor_footer" style={{display:"flex",justifyContent:"space-between"}}>
                                    <div className="pull-left">
                                        <h3>INR <span>52000/-</span></h3>
                                    </div>
                                    <div className="pull-right  ">
                                        <a className="book_now_btn" href="#">View details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                        <div className="explor_item">
                            <a href="room-details.html" className="room_image">
                                <img src="img/room/2.jpg" alt=""/>
                            </a>
                            <div className="explor_text">
                                <a href="room-details.html"><h4>Lovely Kerala</h4></a>
                                <ul>
                                    <li><a href="#">Starting From: </a></li>
                                </ul>
                                <div className="explor_footer">
                                    <div className="pull-left">
                                        <h3>INR <span>26250/-</span></h3>
                                    </div>
                                    <div className="pull-right">
                                        <a className="book_now_btn" href="#">View details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                        <div className="explor_item">
                            <a href="room-details.html" className="room_image">
                                <img src="img/room/3.jpg" alt=""/>
                            </a>
                            <div className="explor_text">
                                <a href="room-details.html"><h4>Singapore With Sentosa - Family Spe</h4></a>
                                <ul>
                                    <li><a href="#">Starting From:</a></li>
                                </ul>
                                <div className="explor_footer">
                                    <div className="pull-left">
                                        <h3>INR <span>65999/-</span></h3>
                                    </div>
                                    <div className="pull-right">
                                        <a className="book_now_btn" href="#">View details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                        <div className="explor_item">
                            <a href="room-details.html" className="room_image">
                                <img src="img/room/4.jpg" alt=""/>
                            </a>
                            <div className="explor_text">
                                <a href="room-details.html"><h4>Bangalore to Delhi</h4></a>
                                <ul>
                                    <li><a href="#">Starting From:</a></li>
                                </ul>
                                <div className="explor_footer">
                                    <div className="pull-left">
                                        <h3>INR <span>3099/-</span></h3>
                                    </div>
                                    <div className="pull-right">
                                        <a className="book_now_btn" href="#">View details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row explor_room_item_inner">
                    <div className="col-md-3 col-sm-6 col-6">
                        <div className="explor_item">
                            <a href="room-details.html" className="room_image">
                                <img src="img/room/5.jpg" alt=""/>
                            </a>
                            <div className="explor_text">
                                <a href="room-details.html"><h4>Delhi to Bangkok</h4></a>
                                <ul>
                                    <li><a href="#">Starting From:</a></li>
                                </ul>
                                <div className="explor_footer">
                                    <div className="pull-left">
                                        <h3>INR <span>2905/-</span></h3>
                                    </div>
                                    <div className="pull-right">
                                        <a className="book_now_btn" href="#">View details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                        <div className="explor_item">
                            <a href="room-details.html" className="room_image">
                                <img src="img/room/6.jpg" alt=""/>
                            </a>
                            <div className="explor_text">
                                <a href="room-details.html"><h4>Hyderabad to Dubai</h4></a>
                                <ul>
                                    <li><a href="#">Starting From: </a></li>
                                </ul>
                                <div className="explor_footer">
                                    <div className="pull-left">
                                        <h3>INR <span>10100/-</span></h3>
                                    </div>
                                    <div className="pull-right">
                                        <a className="book_now_btn" href="#">View details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                        <div className="explor_item">
                            <a href="room-details.html" className="room_image">
                                <img src="img/room/7.jpg" alt=""/>
                            </a>
                            <div className="explor_text">
                                <a href="room-details.html"><h4>Mumbai to Goa</h4></a>
                                <ul>
                                    <li><a href="#">Starting From:</a></li>
                                </ul>
                                <div className="explor_footer">
                                    <div className="pull-left">
                                        <h3>INR <span>1996/-</span></h3>
                                    </div>
                                    <div className="pull-right">
                                        <a className="book_now_btn" href="#">View details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                        <div className="explor_item">
                            <a href="room-details.html" className="room_image">
                                <img src="img/room/8.jpg" alt=""/>
                            </a>
                            <div className="explor_text">
                                <a href="room-details.html"><h4>Mumbai to London</h4></a>
                                <ul>
                                    <li><a href="#">Starting From:</a></li>
                                </ul>
                                <div className="explor_footer">
                                    <div className="pull-left">
                                        <h3>INR <span>17568/-</span></h3>
                                    </div>
                                    <div className="pull-right">
                                        <a className="book_now_btn" href="#">View details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>);
}

export default PackageList;