import { useEffect, useRef, useState } from "react";

function FlightResult() {
    const ref = useRef(false)
    useEffect(()=>{
        if(!ref.current){
            ref.current = true
            var script = document.createElement("script")
            script.innerHTML = `$('.owl-carousel').owlCarousel({
                loop:true,
                margin:40,
                nav:true,
                autoplay:1000,
                responsive:{
                    0:{
                        items:2
                    },
                    600:{
                        items:3
                    },
                    1000:{
                        items:6
                    }
                }
            })`
            document.body.appendChild(script)
        }
        
        
    },[])

    const flights = 
    [
        {
          "flightName": "Akasa Air",
          "flightCode": "QP-1340",
          "departure": "05:30",
          "arrival": "07:10",
          "totalTime": "1h 40m",
          "stop": 5,
          "ticketFare": 8000
        },
        {
          "flightName": "BlueSky Airlines",
          "flightCode": "BS-2235",
          "departure": "08:15",
          "arrival": "09:45",
          "totalTime": "1h 30m",
          "stop": 6,
          "ticketFare": 7200
        },
        {
          "flightName": "SkyJet Airways",
          "flightCode": "SJ-789",
          "departure": "10:00",
          "arrival": "11:40",
          "totalTime": "1h 40m",
          "stop": "0",
          "ticketFare": 8500
        },
        {
          "flightName": "Sunrise Airlines",
          "flightCode": "SA-456",
          "departure": "12:30",
          "arrival": "14:10",
          "totalTime": "1h 40m",
          "stop": 2,
          "ticketFare": 7800
        },
        {
          "flightName": "AirConnect Express",
          "flightCode": "AC-101",
          "departure": "15:20",
          "arrival": "16:50",
          "totalTime": "1h 30m",
          "stop": 1,
          "ticketFare": 7250
        },
        {
          "flightName": "SkyRise Air",
          "flightCode": "SR-7897",
          "departure": "18:00",
          "arrival": "19:40",
          "totalTime": "1h 40m",
          "stop": 3,
          "ticketFare": 8800
        }
      ]
      let calPriceOne = {
         flightName: "",
         flightCode: "",
         departure: "",
         arrival: "",
         totalTime: "",
         stop: 3,
         ticketFare: ""
       }
   
       let calPriceTwo = {
         flightName: "",
         flightCode: "",
         departure: "",
         arrival: "",
         totalTime: "",
         stop: 3,
         ticketFare: ""
       }
    const [data,setData] = useState(flights)
    const [priceOne,setPriceOne] = useState(calPriceOne)
    const [priceTwo,setPriceTwo] = useState(calPriceTwo)

    


    function setPriceDataOne(e,item) {
      setPriceOne({
        ...priceOne, // Copy the existing state
        flightName: item.flightName,
        flightCode: item.flightCode,
        departure: item.departure,
        arrival: item.arrival,
        totalTime: item.totalTime,
        stop: item.stop,
        ticketFare: item.ticketFare,
      });
      const flightItems = document.querySelectorAll(".flightItemLeft");
      flightItems.forEach((flightItem) => {
        flightItem.style.border = "none";
        flightItem.style.backgroundColor = "#fff";
      });
  
   e.currentTarget.style.border = "1px solid #e0626d"

   e.currentTarget.style.backgroundColor = "#ffeaec"
     
    }

    function setPriceDataTwo(e,item){
      setPriceTwo(
    {  ...priceTwo, // Copy the existing state
      flightName: item.flightName,
      flightCode: item.flightCode,
      departure: item.departure,
      arrival: item.arrival,
      totalTime: item.totalTime,
      stop: item.stop,
      ticketFare: item.ticketFare,}
      )

      const flightItems = document.querySelectorAll(".flightItemRight");
      flightItems.forEach((flightItem) => {
        flightItem.style.border = "none";
        flightItem.style.backgroundColor = "#fff";
      });
  
   e.currentTarget.style.border = "1px solid #e0626d"

   e.currentTarget.style.backgroundColor = "#ffeaec"

         console.log(priceTwo)
    }
    return (<>
    <section className="stickyStrip">
    <div className="flightTicketStrip">
   <div className="barCode"><img src="img/barcode.gif"/></div>
   <div className="ticketOne">
      <div className="placeDate">
         <p>New Delhi to Goa Sun, 21 Jul</p>
      </div>
      <div className="flightNameTime">
         <div className="flightNAme">
            <img src="img/flight1.gif" alt=""/>
            <span className="">
               <p>{priceOne.flightName}</p>
            </span>
         </div>
         <div className="flightTimeOne">
            <div className="flightTime">
               <p>{priceOne.departure}</p>
            </div>
            <div className="flightCode">
               <p>DEL</p>
            </div>
         </div>
         <div className="flightHoure">
            <div className="totalTime">
               <p>{priceOne.totalTime}</p>
            </div>
            <div className="totalStop">
               <p>{priceOne.stop}</p>
            </div>
         </div>
         <div className="flightTimeTwo">
            <div className="flightTime">
               <p>{priceOne.arrival}</p>
            </div>
            <div className="flightCode">
               <p>DEL</p>
            </div>
         </div>
      </div>
   </div>
   <div className="ticketTwo">
      <div className="placeDate">
         <p>New Delhi to Goa Sun, 21 Jul</p>
      </div>
      <div className="flightNameTime">
         <div className="flightNAme">
            <img src="img/flight1.gif" alt=""/>
            <span className="">
               <p>{priceTwo.flightName}</p>
            </span>
         </div>
         <div className="flightTimeOne">
            <div className="flightTime">
               <p>{priceTwo.departure}</p>
            </div>
            <div className="flightCode">
               <p>DEL</p>
            </div>
         </div>
         <div className="flightHoure">
            <div className="totalTime">
               <p>{priceTwo.totalTime}</p>
            </div>
            <div className="totalStop">
               <p>{priceTwo.stop}</p>
            </div>
         </div>
         <div className="flightTimeTwo">
            <div className="flightTime">
               <p>{priceTwo.arrival}</p>
            </div>
            <div className="flightCode">
               <p>DEL</p>
            </div>
         </div>
      </div>
   </div>
   <div className="ticketBook">
      <div class="flightPrice">
         <i class="fa fa-inr" aria-hidden="true"></i>
         <span>{(priceOne.ticketFare +  priceTwo.ticketFare).toLocaleString()} <span className="person">/ 4 Adult</span></span>
      </div>
      <button type="submit" class="btn text-center btn-danger book round" style={{marginTop:"5px"}}>BOOK NOW</button>
   </div>
</div>
    </section>
    <section id="show_flights" className="pt-3">
			<div class="container">
				<div class="col-md-12 top-container">
                


				</div>
				<div class="clearfix"></div>
				<div class="col-md-12">
					<div class="row">
						<div class="col-md-8 col-sm-8">
							<div class="cheapest_flights">
								<h4>Cheapest Flights</h4>
								<div class="owl-carousel owl-theme">
				                  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
								  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
								  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
								  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  </div>
							</div>
						</div>
						<div class="col-md-4 col-sm-4">
							<div class="flightPromoCont"><div> <div class="u_inlineblk u_font12">Get upto <span class="currency INR u_clViaRed"></span> <b class="amt u_clViaRed u_font16">7000</b> Off* on International flights</div><div class="u_textL u_fontW400 u_marT8 u_font12">Use code <b class="u_clViaGreen u_txtDecoNone u_padR10 u_padL5">VIAINTL</b></div><div class="u_textL u_fontW300 u_font12 u_marT8 u_marB5"><a href="#" target="_blank"> <span class="u_textDecoUL u_textCapitalize">terms apply</span></a></div></div></div>
						</div>
					</div>
				</div>	


                <div className="flisghtList">
   <div className="leftFlightList">
    {
        data.map((item,i)=>
        <div id={`flightItemleft${i}`} className="flightItem flightItemLeft" onClick={(e)=>setPriceDataOne(e,item)}>
         <div className="nameFlight">
            <img src="https://fastui.cltpstatic.com/image/upload/resources/images/logos/air-logos/svg_logos/QP.svg"/>
            <p><span className="flightCode">{item.flightCode}</span></p>
            
         </div>
         <div className="dep">{item.departure}</div>
         <div className="durationTime">{item.totalTime} <span className="stopcount">{item.stop == "0" ? "non-stop":item.stop + " stop"}</span></div>
         <div className="arr">{item.arrival}</div>
         <div className="rate"><i class="fa fa-inr" aria-hidden="true"></i> {item.ticketFare}</div>
      </div>
        )
    }
      
      
   </div>
   <div className="rightFlightList">
   {
        data.reverse().map((item)=>
        <div className="flightItem flightItemRight"  onClick={(e)=>setPriceDataTwo(e,item)}>
         <div className="nameFlight">
            <img src="https://fastui.cltpstatic.com/image/upload/resources/images/logos/air-logos/svg_logos/QP.svg"/>
            <p><span className="flightCode">{item.flightCode}</span></p>
         </div>
         <div className="dep">{item.departure}</div>
         <div className="durationTime">{item.totalTime} <span className="stopcount">{item.stop == "0" ? "non-stop":item.stop  + " stop"}</span></div>
         <div className="arr">{item.arrival}</div>
         <div className="rate"><i class="fa fa-inr" aria-hidden="true"></i> {item.ticketFare}</div>
      </div>
        )
    }
   </div>
</div>
</div>

		</section>
    </>);
}

export default FlightResult;