import AdultDeatil from "./adultDeatil";
import Infent from "./infent";
import International from "./international";
import './style.css'

function FlightPayment() {
    return (<>
    
    <section id="title_bg">
        	<div class="container">
        		<div class="col-md-12">
        			<div class="title_heading text-center">
        				<h3>Book Domestic and International Flights Payments</h3>
        			</div>
        		</div>
        	</div>
        </section>
       
		<section id="show_flights" class="special-round-trip details_page">
			<div class="container">
                <div class="row">
                    <div class="col-md-12">
                    <div id="flightItemleft0" class="flightItem flightItemLeft">
   <div class="nameFlight">
      <img src="https://fastui.cltpstatic.com/image/upload/resources/images/logos/air-logos/svg_logos/QP.svg"/>
      <p><span class="flightCode">QP-1340</span></p>
   </div>
   <div class="dep" style={{textAlign:"center"}}>05:30 <br/><span style={{fontSize:"14px",borderTop:"1px solid #ddd",paddingTop:"3px"}}>12 May 2024</span></div>
   <div class="durationTime">1h 40m <span class="stopcount">5 stop</span></div>
   <div class="arr" style={{textAlign:"center"}}>07:10 <br/><span style={{fontSize:"14px",borderTop:"1px solid #ddd",paddingTop:"3px"}}>12 May 2024</span></div>
   <div class="rate"><i class="fa fa-inr" aria-hidden="true"></i> 8000</div>
</div>
                    </div>
                </div>
                <div class="row">
    				<div class="col-md-8 col-sm-8 margain_closed" id="main-special-round-trip">					
    					<div class="information_start">
    						<AdultDeatil/>
                            <Infent/>
                            <International/>
                            <div class="details_information">
                                <h3>Contact Details</h3>
                                     <div class="pay-sign-box">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="row">
                                                    <div class="form-group col-md-6">
                                                        <label>Adult1 *</label>
                                                        <select class="form-control">
                                                            <option value="93" data-country-iso="afg" data-country-name="Afghanistan">
                                                                Mr.
                                                            </option>
                                                            <option value="355" data-country-iso="alb" data-country-name="Albania">
                                                                Mrs
                                                            </option>
                                                            <option value="213" data-country-iso="dza" data-country-name="Algeria">
                                                                Ms
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>First Name *</label>
                                                        <input class="form-control" type="text"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="row">
                                                    <div class="form-group col-md-12">
                                                        <label>Last Name *</label>
                                                        <input class="form-control" type="text"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="row">
                                                    <div class="form-group col-md-6">
                                                        <label>Your Email *</label>
                                                        <input class="form-control" type="text"/>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>First Mobile Number *</label>
                                                        <input class="form-control" type="number"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="row">
                                                    <div class="form-group col-md-12"> 
                                                        <div class="checkbox gst_div">
                                                            <div class="panel-group" id="accordion">
                                                                <div class="panel panel-default">
                                                                    <div class="panel-heading">
                                                                        <h4 class="panel-title">
                                                                            <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">I Have a GST Number (Optional)</a>
                                                                        </h4>
                                                                    </div>
                                                                    <div id="collapseTwo" class="panel-collapse collapse">
                                                                        <div class="panel-body">
                                                                            <div class="row">
                                                                                <div class="col-md-12">
                                                                                    <div class="form-group col-md-6">
                                                                                        <label>GST Number *</label>
                                                                                        <input class="form-control" type="text"/>
                                                                                    </div>
                                                                                    <div class="form-group col-md-6">
                                                                                        <label>Email id *</label>
                                                                                        <input class="form-control" type="text"/>
                                                                                    </div>
                                                                                    <div class="form-group col-md-6">
                                                                                        <label>Phone Number *</label>
                                                                                        <input class="form-control" type="text"/>
                                                                                    </div>
                                                                                    <div class="form-group col-md-6">
                                                                                        <label>Address *</label>
                                                                                        <input class="form-control" type="text"/>
                                                                                    </div>
                                                                                    <div class="form-group col-md-6">
                                                                                        <label>Company Name *</label>
                                                                                        <input class="form-control" type="text"/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <label class="left_padding"><input type="checkbox"/> Update my profile with this contact details</label>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <button class="btn btn-info" type="button" id="btnguest">Pay Now</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     </div>
                            </div>
    					</div>
    				</div>
                    <div class="col-md-4 col-sm-4 packages">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="box">
                                    <h4 class="fl-title">Fare Details</h4>
                                    <hr/>
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr><td>Adult x 1</td><td><span class="">INR </span>4,099</td></tr>
                                            <tr><td><strong>Base Fare Total</strong></td><td><span class="">INR </span><strong>4,099</strong></td></tr>
                                            <tr><td><strong>Taxes &amp; fees</strong></td><td></td></tr>
                                            <tr><td>Passenger Service Fee</td><td><span class="">INR </span>153</td></tr>
                                            <tr><td>Additional Tax</td><td><span class="">INR </span>175</td></tr>
                                            <tr><td>Airport Tax</td><td><span class="">INR </span>142</td></tr>
                                            <tr><td>CGST</td><td><span class="">INR </span>107</td></tr>
                                            <tr><td>SGST</td><td><span class="">INR </span>107</td></tr>
                                            <tr><td><strong>Taxes and fees total:</strong></td><td><strong><span class="">INR </span>684</strong></td></tr>
                                            <tr><td><strong>offer price/net total</strong> </td><td><strong><span class="WebRupee">INR </span>4,752</strong></td></tr>
                                            <tr><td><strong>Convenience Fee</strong> </td><td><span class="">INR </span> 250 </td></tr>
                                            <tr><td><strong>Grand Total</strong> </td><td><strong><span class="">INR </span>5,002</strong></td></tr>
                                            <tr class="last_table"><td><h4>You Will be charged</h4></td><td><h4><span class="">INR </span>5,002</h4></td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</section>
    </>);
}

export default FlightPayment;