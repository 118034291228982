import { useEffect, useRef, useState } from "react"
import { Carousel } from "react-bootstrap"

function HotelDetail() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
    return (<>
    <section id="main_accounting_heading">
            <div className="container">
                <div className="col-md-12">
                    <div className="start_here">
                        <h3>Details Page 2</h3>
                    </div>
                </div>
            </div>
        </section>
        <section id="new_details_page">
            <div className="container">
                <div className="col-md-12">
                    <div className="details_page_data">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="left_details_page_con">
                                    <h3>10 Nights 11 Days / South India Temples Tour</h3>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="package_price_data">
                                    <h3>Package Price</h3>
                                    <div className="radio_btns">
                                        <label className="radio">Budget
                                          <input type="radio" checked="checked" name="is_company"/>
                                          <span className="checkround"></span>
                                        </label>
                                        <label className="radio">Deluxe
                                          <input type="radio" name="is_company"/>
                                          <span className="checkround"></span>
                                        </label>
                                        <label className="radio">Luxury
                                          <input type="radio" name="is_company"/>
                                          <span className="checkround"></span>
                                        </label>
                                    </div>
                                    <h4>Rate: Rs. 0 / Person</h4>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2">
                                <div className="details_book_btn">
                                    <a href="#">Book</a>
                                </div>
                            </div>
                        </div>
                        <div className="row" id="margain_top">
                            <div className="col-md-8 col-sm-8">
                            
                                {/* ------------------------------------------------------------------------ */}
                                <div className="details_page_left_big_image">
                             

<Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
      <img src="img/flight1.jpg" alt="" className="d-block w-100"/>
      </Carousel.Item>
      <Carousel.Item>
      <img src="img/flight2.jpg" alt="" className="d-block w-100"/>
      </Carousel.Item>
      <Carousel.Item>
      <img src="img/flight3.jpg" alt="" className="d-block w-100"/>
      </Carousel.Item>
    </Carousel>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="right_side_details_data">
                                    <h3>Destination :</h3>
                                    <ul>
                                        <li>Home</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Chennai</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>TirupatiBalaji</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Chennai</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Mahabalipuram</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Pondicherry</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Thanjavur</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Trichy</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Madurai</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Rameshwaram</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Kanyakumari</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                        <li>Trivandrum Home</li><li><i className="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                                    </ul>
                                    <div className="form_start_here">
                                        <form action="">
                                            <h3>Contact Our Tour Experts</h3>
                                          <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Your Name"/>
                                          </div>
                                          <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Your Email Id"/>
                                          </div>
                                          <div className="form-group">
                                            <select name="cars" className="form-control">
                                              <option value="Select Country">Select Country</option>
                                              <option value="saab">india</option>
                                              <option value="mercedes">india</option>
                                              <option value="audi">india</option>
                                              <option value="audi">india</option>
                                            </select>
                                          </div>
                                          <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Your Phone No"/>
                                          </div>


                                          <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Tour Name"/>
                                          </div>
                                          <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Date Of Travel"/>
                                          </div>
                                          <div className="form-group">
                                            <select name="cars" className="form-control">
                                              <option value="Select Country">Trip Duration</option>
                                              <option value="saab">2</option>
                                              <option value="mercedes">2</option>
                                              <option value="audi">3</option>
                                              <option value="audi">4</option>
                                            </select>
                                          </div>
                                          <div className="form-group">
                                            <select name="cars" className="form-control">
                                              <option value="No. Of Guest">No. Of Guest</option>
                                              <option value="saab">2</option>
                                              <option value="mercedes">2</option>
                                              <option value="audi">3</option>
                                              <option value="audi">4</option>
                                            </select>
                                          </div>
                                          <div className="form-group full_width">
                                            <select name="cars" className="form-control">
                                              <option value="Hotel Category">Hotel Category</option>
                                              <option value="saab">2</option>
                                              <option value="mercedes">2</option>
                                              <option value="audi">3</option>
                                              <option value="audi">4</option>
                                            </select>
                                          </div>
                                          <div className="form-group full_width">
                                            <textarea type="text" className="form-control" placeholder="Requirements"></textarea> 
                                          </div>
                                          <button type="submit" className="btn btn-default">Get a Quote</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="all_details_flight_data">
                                    <div className="details_main_data">
                                        <h3>Your Tour Itinerary:</h3>
                                        <h5><b>Day 01: Home - Chennai</b></h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                        <h5><b>Day 02: Home - Mumbai</b></h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                        <h5><b>Day 03: Home - Mumbai</b></h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                        <h5><b>Day 04: Home - Mumbai</b></h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </div>
                                    <div className="details_main_data">
                                        <h2><b>** Tour Ends **</b></h2>

                                        <h3>Inclusions</h3>
                                        <b>Hotel</b>
                                        <table id="customers">
                                          <tr>
                                            <th>City Name</th>
                                            <th>Hotel Name</th>
                                            <th>Hotel Type</th>
                                          </tr>
                                          <tr>
                                            <td>ABC</td>
                                            <td>ABC</td>
                                            <td>ABC</td>
                                          </tr>
                                        </table>
                                    </div>
                                    <div className="details_main_data">
                                        <h3>About The Place:</h3>
                                        <h5><b>About Chennai</b></h5>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default HotelDetail;