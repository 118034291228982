import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import Home from "./page/home/home";
import OneWaytrip from "./page/oneWaytrip/oneWaytrip";
import RoundTrip from "./page/roundTrip/roundTrip";
import SpecialRoundTrip from "./page/specialRoundTrip/specialRoundTrip";
import Multcityflight from "./page/multcityflight/multcityflight";
import FlightBook from "./page/flightBook/flightBook";
import FlightPayment from "./page/flightPayment/flightPayment";
import Myaccount from "./page/myaccount/myaccount";
import AgentLogin from "./page/agentLogin/agentLogin";
import Hotel from "./page/hotel/hotel";
import Ticket from "./page/ticket/ticker";
import BookingPage from "./page/bookingPage/booking";
import HotelDetail from "./page/hotel/hotelDetail";
import CancelTicket from "./page/flightPayment/cancellTicket";
import HotelView from "./page/hotel/hotelView";
import HotelBook from "./page/hotel/hotelBook";

const Router =  createBrowserRouter([
    {
      path: "/",
      element: <App/>,
      errorElement: "",
      children: [
        { path: "/",
          element: <Home />,
          index:true,
        },
        {
          path: "/oneWay",
          element: <OneWaytrip/>,
        },
        {
          path: "/roundTrip",
          element: <RoundTrip/>,
        },
        {
          path: "/specialRoundTrip",
          element: <SpecialRoundTrip/>,
        },
        {
          path: "/multcityflight",
          element: <Multcityflight/>,
        },
        {
          path: "/flightBookingDetails",
          element: <FlightBook/>,
        },
        {
          path: "/flightPayment",
          element: <FlightPayment/>,
        },
        {
          path: "/cancelTicket",
          element: <CancelTicket/>,
        },
        {
          path: "/myaccount",
          element: <Myaccount/>,
        },
        {
          path: "/hotel",
          element: <Hotel/>,
        },
        ,
        {
          path: "/hotelDetail",
          element: <HotelDetail/>,
        },
        {
          path: "/hotelView",
          element: <HotelView/>,
        },
        {
          path: "/hotelBook",
          element: <HotelBook/>,
        },
        {
          path: "/ticket",
          element: <Ticket/>,
        },
        {
          path: "/bookinginfo",
          element: <BookingPage/>,
        }
      ],
    },{
      path: "/agentLogin",
      element: <AgentLogin/>,
    }
    
  ]);
export default Router;