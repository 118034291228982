import { Link } from "react-router-dom";
import './style.css'

function Footer() {
    return (<>
    <footer className="footer_area">
            <div className="footer_widget_area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-xs-6 col-sm-3">
                            <aside className="f_widget about_widget">
                                <img src="https://www.seturtrip.com/wp-content/uploads/2023/07/logo2.webp" style={{width:"auto",height:75}} alt=""/>
                                <p>Seturtrip.com, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </aside>
                        </div>
                        <div className="col-md-8 col-xs-6 col-sm-3">
<div className="d-flex">
                            <aside className="f_widget link_widget">
                                <div className="f_title">
                                    <h3>Important Links</h3>
                                </div>
                                <ul>
                                    <li><Link to="/">-  Home</Link></li>
                                    <li><Link to="/oneWay">-  One Way</Link></li>
                                    <li><Link to="/roundTrip">-  Round Trip</Link></li>
                                    <li><Link to="/specialRoundTrip">-  Special Round Trip</Link></li>
                                    <li><Link to="/multcityflight">-  Multicity Flights</Link></li>
                                    <li><Link to="/myaccount">-  My Account</Link></li>
                                    <li><Link to="/hotel">-  Hotel Search List</Link></li>
                                    <li><Link to="/hotelbook">-  Hotel Booking</Link></li>
                                    <li><Link to="/hoteldetail">-  Hotel Detail</Link></li>
                                    <li><Link to="/hotelview">-  Hotel View</Link></li>
                                    
                                </ul>
                            </aside>

                            <aside className="f_widget link_widget">
                                <div className="f_title">
                                    <h3>our Package</h3>
                                </div>
                                <ul className="dt-sc-fancy-list   arrow">
									<li><Link to="/flightBookingDetails">- Flight Booking Details</Link></li>
									<li><Link to="/flightPayment">- Flight Booking Payment</Link></li>
									<li><Link to="/flightBookingDetails">-  Flight Booking</Link></li>
                                    <li><Link to="/ticket">-  Ticket</Link></li>
                                    <li><Link to="/bookinginfo">-  Booking Information</Link></li>
                                    <li><Link to="/flightbook">-  Flight Book</Link></li>
                                    <li><Link to="/booking">-  Booking</Link></li>
								</ul>	
                            </aside>

                            <aside className="f_widget link_widget">
                                <div className="f_title">
                                    <h3>our Package</h3>
                                </div>
                                <ul className="dt-sc-fancy-list   arrow">
									<li><Link to="/flightPayment">-  flight Payment</Link></li>
                                    <li><Link to="/bookinginfo" state={{bookType:"hotel"}}>-  Confirm Hotel</Link></li>
                                    <li><Link to="/bookinginfo" state={{bookType:"flight"}}>-  Confirm flight</Link></li>

								</ul>	
                            </aside>

                            <aside className="f_widget link_widget">
                                <div className="f_title">
                                    <h3>our Package</h3>
                                </div>
                                <ul className="dt-sc-fancy-list   arrow">
									
								</ul>	
                            </aside>

                    	</div>
                        </div>
                	</div>
                	<div className="row" id="main_footer_border">
                		<div className="col-md-3 col-sm-3">
                			<div className="ab_wd_list">
                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-map-marker"></i>
                                    </div>
                                    <div className="media-body">
                                        <h4>B-5/269, Ground Floor, Sec-5, Rohini, Delhi-110085</h4>
                                    </div>
                                </div>
                             </div>
                		</div>
                		<div className="col-md-3 col-sm-3">
							<div className="ab_wd_list">
	                            <div className="media">
	                                <div className="media-left">
	                                    <i className="fa fa-envelope"></i>
	                                </div>
	                                <div className="media-body">
	                                	<h4>info@Seturtrip.com</h4>										
	                                </div>
	                            </div>
                            </div>
                		</div>
                		<div className="col-md-3 col-sm-3">
							<div className="ab_wd_list">
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="fa fa-phone"></i>
                                        </div>
                                        <div className="media-body">
                                        	<h4>Mon-Fri 9:00 am till 6:00 pm</h4>
                                            <h4>+91 9999866662</h4>											
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="col-md-3 col-sm-3">
							<div className="ab_wd_list">
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="fa fa-phone"></i>
                                        </div>
                                        <div className="media-body">
                                        	<h4>Mon-Fri 9:00 am till 6:00 pm</h4>
                                            <h4>+91 9999866662</h4>											
                                        </div>
                                    </div>
                                </div>
                        </div>
                	</div>
            	</div>
            	<div className="footer_copyright_area">
                <div className="container">
                    <div className="pull-left">
                        <h4>Copyright © 2018 Seturtrip | All rights reserved. </h4>
                    </div>
                    <div className="pull-right">
                        <h4>Created by: <a href="https://flamingoinfotech.com/" target="_blank">flamingoinfotech</a></h4>
                    </div>
                </div>
            	</div>
            </div>
        </footer>
    </>);
}

export default Footer;