import { Col, Container, Row, Table } from "react-bootstrap";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './style.css'
import { Chip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
function BookingPage() {
    const location = useLocation()
    const bookType = location.state.bookType
    return (<>
    <Container>
        {bookType == "flight"? 
        <Row className="gx-0">
            <Col sm={9}>
            <div className="confirmSection">
        <h2>Your Flight booking is confirmed</h2>
        <div className="idDate">
            <p><span>Booking ID:</span> MF24LS13V1SD32V1S3DV1</p>
            <p><span>Booking On:</span> 09-Nov-2023</p>
        </div>
    </div>
    <div className="ticketInfo">
        <div className="wayto">
            <h2>Banglore <i class="fa fa-long-arrow-right" aria-hidden="true"></i> Kolkata <Chip icon={<CheckCircleIcon color={"success"} />} label="Cofirmed" sx={{backgroundColor:"#BDF2DE"}} /></h2>
        </div>
        <hr/>
        <div className="ticketContent">
         <div className="flightName">
            <div className="flightImg"><img src="img/UK.gif" /></div>
            <div className="nameTitleCode">
               <div className="nameTitle"><b>SpiceJet</b></div>
               <div className="nameCode">SG-SG 136</div>
            </div>
         </div>
         <div className="flightDateTime">
            <div className="airportName"><p><b>BLR~India {"(BLR)"}</b></p></div>
            <div className="airportCode"><p>BLR</p></div>
            <div className="terminal"><p>Terminal</p></div>
            <div className="flightTimes"><p><b>Sun, 12 Nov, 2023 05:55</b></p></div>
         </div>
         <div className="flightDateTime">
            <div className="airportName"><p><b>BLR~India {"(BLR)"}</b></p></div>
            <div className="airportCode"><p>BLR</p></div>
            <div className="terminal"><p>Terminal</p></div>
            <div className="flightTimes"><p><b>Sun, 12 Nov, 2023 05:55</b></p></div>
         </div>
         <div className="stop"><p><b>Non Stop</b></p><br/><p>02:30</p></div>
      </div>
      <hr/>
      <div className="traveller">
        <h5><b style={{color:"#000"}}>Traveller Details</b></h5>
      <Table className="mb-0 mt-3"  striped bordered hover>
        <tr>
            <th>Sr. No.</th>
            <th>Passenger Name</th>
            <th>Type</th>
            <th>PNR</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Mr Aditya Narain Mukherjee</td>
            <td>Adult</td>
            <td>HYHBWC</td>
        </tr>
      </Table>
      </div>
    </div>
            </Col>
            <Col sm={3}>
                <div className="sidePanal">
                    <h3 className="sidePanalHeading">Change in Plans</h3>
                    <p className="optionlist"><span className="icon"><i class="fa fa-user" aria-hidden="true"></i></span><span className="textLine">Cancel Specific Traveller</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-address-book" aria-hidden="true"></i></span><span className="textLine">Cancel Full Booking</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-calendar" aria-hidden="true"></i></span><span className="textLine">Chnage Travell Date</span></p>
                    <hr/>
                    <h3 className="sidePanalHeading">Submit Refund Request</h3>
                    <p className="optionlist"><span className="icon"><i class="fa fa-money" aria-hidden="true"></i></span><span className="textLine">I directly cancelled with the airline</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-plane" aria-hidden="true"></i></span><span className="textLine">My Flight was cancelled by the airline</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-plane" aria-hidden="true"></i></span><span className="textLine">Flight rescheduled & did not travel</span></p>
                    <hr/>
                    <h3 className="sidePanalHeading">Submit Refund Request</h3>
                    <p className="optionlist"><span className="icon"><i class="fa fa-ticket" aria-hidden="true"></i></span><span className="textLine"><Link to="/ticket">Download E-Ticket</Link></span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-envelope" aria-hidden="true"></i></span><span className="textLine">Email E-Ticket</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-file-text" aria-hidden="true"></i></span><span className="textLine">Download Invoice</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-address-book" aria-hidden="true"></i></span><span className="textLine">Customer Booking Confirmation</span></p>
                </div>
            </Col>
        </Row>
        :
        <Row className="gx-0">
            <Col sm={9}>
            <div className="confirmSection">
        <h2>Your Flight booking is confirmed. No Need to call Hotel for Information</h2>
        <div className="idDate">
            <p><span>Booking ID:</span> MF24LS13V1SD32V1S3DV1</p>
            <p><span>Booking On:</span> 09-Nov-2023</p>
        </div>
    </div>
    <div className="ticketInfo">
        <div className="wayto" style={{display:"flex"}}>
        <div style={{width:"80%"}}>
        <Chip label="Couple Friendly" />
            <h2 className="mt-3">Ayodhya Place Beach Resort </h2>
            <p style={{fontSize:"12px",fontWeight:"600"}}>112/2, Moo 3, Ao-Nang Amphur Muang Krabi 81000, Thailand</p>
            <p style={{fontSize:"12px",fontWeight:"600",color:"#000"}}><b>Contect:</b> +66 88 765 6869</p>
        </div>
        <div style={{width:"20%"}}><img className="hotelImage" src="https://www.tajhotels.com/content/dam/luxury/hotels/Taj_Mahal_Delhi/images/gallery/rooms-suites/images_2021/taj-mahal-photo-new.jpg/jcr:content/renditions/cq5dam.web.1280.1280.jpeg"/></div>
        </div>
        <hr/>
        <div className="ticketContent">
         
         <div className="flightDateTime">
            <div className="airportName"><p><b>CHECK - IN {"(Krabi Time)"}</b></p></div>
            <div className="flightTimes mt-3"><p style={{display:"flex",justifyContent:"center",alignItems:"center",}}><b style={{fontSize:"50px",color:"#000",marginRight:"15px"}}> 12 </b><span>Nov, 2023<br/>Sun, 05:55</span></p></div>
         </div>
         <div className="stop" style={{display:"flex",justifyContent:"center",alignItems:"center"}}><p><b>2 Night Stay</b></p><br/><p></p></div>
         <div className="flightDateTime"> 
            <div className="airportName"><p><b>CHECK - OUT {"(Krabi Time)"}</b></p></div>
            <div className="flightTimes mt-3"><p style={{display:"flex",justifyContent:"center",alignItems:"center",}}><b style={{fontSize:"50px",color:"#000",marginRight:"15px"}}> 12 </b><span>Nov, 2023<br/>Sun, 05:55</span></p></div>         </div>
         <div style={{width:"20%"}}><img className="hotelImage" src="https://www.google.com/maps/d/thumbnail?mid=1YzngLKIYrfFajmxwAE3xU0RyMxw&hl=en_US"/></div>
      </div>
      <hr/>
      <div className="traveller">
        <h5><b style={{color:"#000"}}>Traveller Details</b></h5>
      <Table className="mb-0 mt-3"  striped bordered hover>
        <tr>
            <th>Rooms</th>
            <th>Room Type</th>
            <th>Adult</th>
            <th>Childer</th>
            <th>Meal</th>

        </tr>
        <tr>
            <td>1</td>
            <td>One-Bedroom Suite Pool Access</td>
            <td>2</td>
            <td>2</td>
            <td>Breakfast</td>
        </tr>
      </Table>
      </div>
    </div>
            </Col>
            <Col sm={3}>
                <div className="sidePanal">
                    <h3 className="sidePanalHeading">Change in Plans</h3>
                    <p className="optionlist"><span className="icon"><i class="fa fa-user" aria-hidden="true"></i></span><span className="textLine">Cancel Specific Traveller</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-address-book" aria-hidden="true"></i></span><span className="textLine">Cancel Full Booking</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-calendar" aria-hidden="true"></i></span><span className="textLine">Chnage Check - IN/OUT Date</span></p>
                    <hr/>
                    <h3 className="sidePanalHeading">Submit Refund Request</h3>
                    <p className="optionlist"><span className="icon"><i class="fa fa-money" aria-hidden="true"></i></span><span className="textLine">I directly cancelled with the room</span></p>
                    
                    <hr/>
                    <h3 className="sidePanalHeading">Submit Refund Request</h3>
                    <p className="optionlist"><span className="icon"><i class="fa fa-ticket" aria-hidden="true"></i></span><span className="textLine">Download E-Ticket</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-envelope" aria-hidden="true"></i></span><span className="textLine">Email E-Ticket</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-file-text" aria-hidden="true"></i></span><span className="textLine">Download Invoice</span></p>
                    <p className="optionlist"><span className="icon"><i class="fa fa-address-book" aria-hidden="true"></i></span><span className="textLine">Customer Booking Confirmation</span></p>
                </div>
            </Col>
        </Row>}
    </Container>
    </>);
}

export default BookingPage;