import './style.css'
function Testimonials() {
    return (<>
     <section id="Testimonial-area">
        	 <div className="container">
	        	<div className="explor_title row m0">
	                    <div className="title-text">
	                        <div className="left_ex_title"> 
	                            <h2>Client <span>Testimonials</span></h2>
	                        </div> 
	                    </div>
	             </div>
	             <div className="col-md-12">
                 
                <div className="carousel slide" data-bs-ride="carousel" id="quote-carousel">
                    
                    <div className="carousel-inner text-center">
                        
                        <div className="carousel-item active">
                            <blockquote>
                                <div className="row">
                                    <div className="col-sm-12">
                                    	<div className="testimonial-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. .</p>
                                        <small>Someone famous</small>
                                        <b>Destination</b>
                                        </div>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                       
                        <div className="carousel-item">
                            <blockquote>
                                <div className="row">
                                    <div className="col-sm-12">
                                    	<div className="testimonial-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. .</p>
                                        <small>Someone famous</small>
                                        <b>Destination</b>
                                        </div>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                        
                        <div className="carousel-item">
                            <blockquote>
                                <div className="row">
                                    <div className="col-sm-12">
                                    	<div className="testimonial-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. .</p>
                                        <small>Someone famous</small>
                                        <b>Destination</b>
                                        </div>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                   <div style={{display:"flex",justifyContent:"space-between",width:"92px",margin:"0 auto"}}>
                   <button class="left carousel-control-prev" type="button" data-bs-target="#quote-carousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="right carousel-control-next" type="button" data-bs-target="#quote-carousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
                  
                   </div>
                    {/* <a data-slide="prev" href="#quote-carousel" role="button"  className="left carousel-control-prev"><i className="fa fa-chevron-left"></i></a>
                    <a data-slide="next" href="#quote-carousel" role="button"  className="right carousel-control-next"><i className="fa fa-chevron-right"></i></a> */}
                </div>
                
            </div>
       	    </div>
            
        </section>
    </>);
}

export default Testimonials;