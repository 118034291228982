import { Link } from "react-router-dom";
import  "./style.css"
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function Header() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (<>
    
        <header className="main_header_area">
        	<div className="top_patti">
          <div className="container">
          <div className="row">
             <div className="col-md-4"></div>
          	 	<div className="col-md-8 col-sm-12">
             		<div className="header_top">
        			<div className="row">
        			<div className="col-md-6 col-sm-6">
	                	<div className="header_top_inner">                        
	                    	<div className="lophnnumber">   
    	                        <a href="#"><i className="fa fa-phone"></i>+91 9999866662</a>
    	                        <a href="#"><i className="fa fa-envelope"></i>info@Seturtrip.com</a>
	                   		</div>
	                    </div>
                	</div>
                	<div className="col-md-6 col-sm-6">
	                    <ul className="header_social">
	                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
	                        <li><a href="#"><i className="fa fa-instagram"></i></a></li>                                
	                    	<li><Link to="/myaccount" className="res">My Account</Link></li>
                            <li><Link  className="res"  onClick={handleShow}>Login</Link></li>
	                    	<li><Link to="/agentLogin" className="res">AGENT</Link></li>
	                    </ul>
               		</div>
                	</div>
             		</div>
             	</div>
                 </div>
           </div>
        	</div>



            <div className="header_menu">
                <nav className="navbar navbar-default navbar-expand-lg">
                    <div className="container">
                        {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <Link className="navbar-brand" to="/">
                                <img src="https://www.seturtrip.com/wp-content/uploads/2023/07/logo2.webp" style={{width:"auto",height:75}} alt=""/>
                                <img src="img/logo-sticky.jpg" alt=""/>
                            </Link>
                        </div>

                        {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <ul className="nav navbar-nav" style={{marginLeft:"auto"}}>
                                <li><Link to="/"><span className="fa fa-home"></span>Home</Link></li>
                                <li><a href="#"><span className="fa fa-plane"></span>Flight</a></li> 
                                <li><a href="#"><span className="fa fa-bed"></span>Hotels</a></li>
                                <li className="submenu dropdown"><a href="#"><span className="fa fa-star"></span>Your Package</a>
                                    <ul className="dropdown-menu">
                                        <li><a href="#">Heading</a></li>
                                        <li><a href="#">Heading2</a></li>
                                        <li><a href="#">Heading3</a></li>
                                        <li><a href="#">Heading4</a></li>
                                    </ul>
                                </li>
                                <li><a href="#"><span className="fa fa-check"></span>Offers</a></li>
                                <li><a href="#"><span className="fa fa-phone"></span>Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color:"#000"}}>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="form-group"><label><b style={{color:"#000"}}>Mobile oe Email*</b></label><input className="form-control" type="text"/></div>
        <button class="btn btn-info" type="button" id="btnguest" style={{marginTop:"0"}}>Continue</button>
        <p className="text-center mt-3"><b className="other-section">Or Login/Signup With</b></p>
        <p className="text-center mt-3"><img src="img/google.png" style={{width:"50px"}}/></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> 
    </>);
}

export default Header;