import { Link } from 'react-router-dom';
import './style.css'
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
const columns = [
    { field: 'id', headerName: 'ID', width: 10,},
    { field: 'date', headerName: 'Date', },
    { field: 'transactionType', headerName: 'Type',  },
    { field: 'passangerName', headerName: 'Passanger Name', 
   
},
{
    field: 'pnrnumber',
    headerName: 'PNR',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
   
  },
  { field: 'date', headerName: 'Travel Date', },
  { field: 'debit', headerName: 'Debit', },
  { field: 'credit', headerName: 'Credit', },
    { field: 'payentId', headerName: 'Transaction ID',width:150},
    
    { field: 'amount', headerName: 'Balaced', },
    {
        headerName: 'Remark',
        sortable: false,
        
        renderCell:()=>{
            return(
              <p>This column has a value getter and is not sortable.</p>
            )
        }
      },
  ];
  
  const rows = [
    { id: 1, pnrnumber: 'HYHBWC', payentId: 'JVADE0B248932', amount: 35, date:"20/5/2021",transactionType:"Flight",passangerName:["Wade Kumar","Dave gupta","Seth thakur","Ivan singh","Riley juneja","Gilbert poddar"],debit:"5",credit:"5"},
    { id: 2, pnrnumber: 'HYHBWC', payentId: 'ACRAF23DB3C4', amount: 42, date:"20/5/2021",transactionType:"Hotel",passangerName:["Wade Kumar","Dave gupta","Seth thakur","Ivan singh","Riley juneja","Gilbert poddar"],debit:"5",credit:"5"},
    { id: 3, pnrnumber: 'HYHBWC', payentId: 'JVADE0B248932', amount: 45, date:"20/5/2021",transactionType:"Flight",passangerName:["Wade Kumar","Dave gupta","Seth thakur","Ivan singh","Riley juneja","Gilbert poddar"],debit:"5",credit:"5"},
    { id: 4, pnrnumber: 'HYHBWC',  payentId: 'ACRAF23DB3C4', amount: 16, date:"20/5/2021",transactionType:"Hotel",passangerName:["Wade Kumar","Dave gupta","Seth thakur","Ivan singh","Riley juneja","Gilbert poddar"],debit:"5",credit:"5"},
    { id: 5, pnrnumber: 'HYHBWC', payentId: 'JVADE0B248932', amount: 55, date:"20/5/2021",transactionType:"Flight",passangerName:["Wade Kumar","Dave gupta","Seth thakur","Ivan singh","Riley juneja","Gilbert poddar"],debit:"5",credit:"5"},
    { id: 6, pnrnumber: 'HYHBWC',  payentId: 'ACRAF23DB3C4', amount: 150, date:"20/5/2021",transactionType:"Hotel",passangerName:["Wade Kumar","Dave gupta","Seth thakur","Ivan singh","Riley juneja","Gilbert poddar"],debit:"5",credit:"5"},
    { id: 7, pnrnumber: 'HYHBWC', payentId: 'JVADE0B248932', amount: 44, date:"20/5/2021",transactionType:"Flight",passangerName:["Wade Kumar","Dave gupta","Seth thakur","Ivan singh","Riley juneja","Gilbert poddar"],debit:"5",credit:"5"},
    { id: 8, pnrnumber: 'HYHBWC',  payentId: 'ACRAF23DB3C4', amount: 36, date:"20/5/2021",transactionType:"Hotel",passangerName:["Wade Kumar","Dave gupta","Seth thakur","Ivan singh","Riley juneja","Gilbert poddar"],debit:"5",credit:"5"},
    { id: 9, pnrnumber: 'HYHBWC', firstName: 'Harvey', amount: 65 , date:"20/5/2021",transactionType:"Flight",passangerName:["Wade Kumar","Dave gupta","Seth thakur","Ivan singh","Riley juneja","Gilbert poddar"],debit:"5",credit:"5"},
  ];
function Myaccount() {
    return (<>
        <section id="main_accounting_heading">
        	<div className="container">
        		<div className="col-md-12">
        			<div className="start_here">
        				<h3>My Account</h3>
        			</div>
        		</div>
        	</div>
        </section>
         <section id="body_data_start">
         	<div className="container">
         		<div className="row">
            		<div className="col-sm-4">
            			<div className="left_side_tab">
			                <ul className="nav nav-pills brand-pills nav-stacked" role="tablist">
			                	<li className="tab_cls text-center"><img src="https://www.seturtrip.com/wp-content/uploads/2023/07/logo2.webp" al="" style={{width:"auto",height:75}} alt="" className="tab_logo img-responsive"/></li>
			                    <li role="presentation" className="brand-nav active"><a href="#tab1" aria-controls="tab1" role="tab" data-toggle="tab" aria-expanded="false">My Profile <i className="pull-right fa fa-user"></i></a></li>
			                    
			                    <li role="presentation" className="brand-nav"><a href="#tab2" aria-controls="tab3" role="tab" data-toggle="tab" aria-expanded="false">All <i className="pull-right fa fa-th-list"></i></a></li>

			                    <li role="presentation" className="brand-nav"><a href="#tab3" aria-controls="tab3" role="tab" data-toggle="tab" aria-expanded="false">Change Password <i className="pull-right fa fa-key"></i></a></li>

			                    <li role="presentation" className="brand-nav"><a href="#tab4" aria-controls="tab3" role="tab" data-toggle="tab" aria-expanded="false">My Wallet <i className="pull-right fa fa-key"></i></a></li>
			                </ul>
		            	</div>
            		</div>
            		<div className="col-sm-8">
                		<div className="tab-content">
                    <div role="tabpanel" className="tab-pane active" id="tab1">
                       <div className="information_start">
						<div className="details_information">
							<h3>Your Personal Information</h3>
							<div className="pay-sign-box">
                            <div className="row">
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                        	<select className="form-control">
											  <option value="Mr">Mr</option>
											</select>
                                        </div>
                                        <div className="form-group col-md-3">                                            
                                            <input type="text" className="form-control" placeholder="First Name" id="FName" name="FirstName" value="Dharmesh"/>
                                        </div>
                                        <div className="form-group col-md-3">
                                        	<input type="text" className="form-control" placeholder="Middle Name" value="" name="Middlename"/>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <input type="text" className="form-control" placeholder="Last Name" id="LName" value="Sharma" name="LastName"/>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <input type="text" id="datepicker" className="new-form-cls" value="12/6/2019"/>
                                        </div>
                                      
                                        <div className="form-group col-md-3">
                                            <input type="email" className="form-control" placeholder="Email ID" id="Email" name="Email" readonly="readonly" value="Dharmesh@maraekat.com"/>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <input type="text" className="form-control" placeholder="Contact No." id="Mobile" value="7506203006" name="Contact"/>
                                        </div>
                                    </div>

                                    <div className="row">
					                    <div className="col-md-3 form-group">
					                        <input type="text" className="form-control" placeholder="Country" id="Country" value="India" name="Country"/>
					                    </div>

					                    <div className="col-md-3 form-group">
					                        <input type="text" className="form-control" id="State" value="MH" placeholder="State" name="State"/>
					                    </div>

					                    <div className="col-md-3 form-group">
					                        <input type="text" className="form-control" placeholder="City" name="City" id="City" value="Mumbai"/>
					                    </div>

					                    <div className="col-md-3 form-group">
					                        <input type="text" className="form-control" placeholder="Pin Code" name="pincode" id="Pincode" value="204101"/>
					                    </div>
					                </div>

					                <div className="row">
					                	<div className="col-md-12 form-group">					                		
                        				<textarea cols="30" rows="3" className="form-control" placeholder="Address" id="Address" name="Address" spellcheck="false">Mumbai</textarea>                        
                    					</div>
					                </div>

                                    <div className="row">
                                    	<div className="form-group col-md-6">
                                            <button className="btn btn-info" type="button" id="btnlogin">Update</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
						</div>
					</div>
                    </div>
                    <div role="tabpanel" className="tab-pane" id="tab2">
                        
                        
                        <ul className="booking-stat-ul pad0" id="alldata">
                        	<li role="presentation" className="">
                            <a href="#hotel" aria-controls="hotel" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="hotel" data-bookingid="12" data-session="e0d5b39d-6c71-440e-b41e-278db12ddd08" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-bed" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">26 May 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-complete">Completed</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </a>
                        	</li>
                       		<li role="presentation" className="">
                            <a href="#flight" aria-controls="flight" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="flight" data-bookingid="89" data-session="9a8e982f-1b99-4d4b-a477-048d2bf16f93" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-plane i-plane" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">15 Jun 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-warning">Cancelled</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </a>
                        	</li>
                        	<li role="presentation" className="">
                            <a href="#hotel" aria-controls="hotel" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="hotel" data-bookingid="2" data-session="0183490d-dde0-4431-b4d3-01d2aaa493e7" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-bed" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">19 May 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-complete">Completed</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </a>
                        	</li>
                        	<li role="presentation" className="">
                            <a href="#hotel" aria-controls="hotel" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="hotel" data-bookingid="2" data-session="0183490d-dde0-4431-b4d3-01d2aaa493e7" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-bed" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">19 May 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-complete">Completed</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </a>
                        	</li>
                        	<li role="presentation" className="">
                            <a href="#hotel" aria-controls="hotel" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="hotel" data-bookingid="2" data-session="0183490d-dde0-4431-b4d3-01d2aaa493e7" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-bed" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">19 May 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-complete">Completed</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </a>
                        	</li>
                        	<li role="presentation" className="">
                            <a href="#flight" aria-controls="flight" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="flight" data-bookingid="60" data-session="48dc071c-a146-4d19-96ab-18b440bf8321" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-plane i-plane" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">2ss5 May 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-complete">Completed</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </a>
                        	</li>
                        	<li role="presentation" className="">
                            <Link to="/bookinginfo" state={{bookType:"hotel"}} aria-controls="flight" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="flight" data-bookingid="40" data-session="c4b49af1-1315-4e1f-bbe0-67481ff7b120" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-plane i-plane" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">25 May 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-complete">Completed</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Link>
                        	</li>
                        	<li role="presentation" className="">
                            <Link to="/bookinginfo" state={{bookType:"flight"}} href="#flight" aria-controls="flight" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="flight" data-bookingid="20" data-session="538cc33a-92ae-4c46-af3d-dda69b57123d" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-plane i-plane" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">23 Jul 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-complete">Upcoming</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Link>
                        	</li>
                        	<li role="presentation" className="">
                            <a href="#flight" aria-controls="flight" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="flight" data-bookingid="19" data-session="538cc33a-92ae-4c46-af3d-dda69b57123d" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-plane i-plane" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">19 Jul 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-complete">Upcoming</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </a>
                        	</li>
                        	<li role="presentation" className="">
                            <a href="#flight" aria-controls="flight" role="tab" data-toggle="tab" aria-expanded="true" data-bookingtype="flight" data-bookingid="4" data-session="cf789ea0-8f86-4218-b8f4-3f2f6e72f095" onclick="BookingDetails(this)">
                                <div className="row marg0">
                                    <div className="col-md-12 text-center pad0">
                                        <table className="table marg0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                                <i className="fa fa-plane i-plane" aria-hidden="true"></i>

                                                    </td>
                                                    <td><span className="span-date">24 Jul 2019</span></td>

                                                    <td>
                                                                <span className="badge badge-complete">Upcoming</span>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </a>
                        	</li>
            		</ul>
                    </div>
                    <div role="tabpanel" className="tab-pane" id="tab3">
                        <div className="information_start">
						<div className="details_information">
							<h3>Change Password</h3>
							<div className="pay-sign-box">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <label>Current Password</label>
								                <input type="password" className="form-control" placeholder="Current Password"/> 
								         </div> 
                                        <div className="form-group col-md-6">
                                            <label>New Password</label>
								                <input type="password" className="form-control" placeholder="New Password"/> 
								        </div>
                                        <div className="form-group col-md-6">
                                            <label>Confirm Password</label>
								                <input type="password" className="form-control" placeholder="Confirm Password"/> 
								        </div>  
                                        <div className="form-group col-md-6">
                                            <button className="btn btn-info" type="button" id="btnguest">Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
						</div>
					</div>
                    </div>
                    <div role="tabpanel" className="tab-pane" id="tab4">
                        <div className="information_start">
						<div className="details_information">
							<h3>Available Balance: <span style={{color: "green"}}>0.00/-</span></h3>
							<div className="pay-sign-box">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <label>Enter Amount</label>
								                <input type="password" className="form-control" placeholder="Enter Amount" required/> 
								         </div>   
                                        <div className="form-group col-md-6">
                                            <button className="btn btn-info" type="submit" id="btnguest" style={{marginTop: "0"}}>Add Money</button>
                                        </div>
                                        <div className="form-group col-md-12">
	                                        <p style={{display:"block",width:"100%"}}>! Maximum limit to add money is 10000.00 per month and 100000.00 per year.</p>
	                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 400, width: '100%',marginTop:"30px" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
       // checkboxSelection
       disableColumnFilter
        disableColumnSelector
        disableDensitySelector
       slots={{ toolbar: GridToolbar }}
       slotProps={{
         toolbar: {
           showQuickFilter: true,
         },
       }}
      />
      </div>
						</div>
					</div>
                    </div>
                		</div>
					</div>
				</div>
         	</div>
         </section>
    </>);
}

export default Myaccount;