import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import './style.css'

function MoreDetails() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (<>
    <button className='moreFare' onClick={handleShow}>+ More Fare</button>
    <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-tit">
            <img src="img/flight1.gif" alt=""/>
            <div>
                <p style={{fontSize:"20px",color:"#000"}}><b>Mumbai 16:35 - Delhi 14:52</b></p>
                <p  style={{fontSize:"15px",marginTop:"10px",fontWeight:600}}>25 Nav 2023 , Indigo (SG - 8169), 2H:25M, Non Stop</p>
            </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form style={{marginBottom:"15px"}}>
        <Form.Check // prettier-ignore
            type={"checkbox"}
            id="checkbox"
            label="Refundable"
            inline
          />
          <Form.Check // prettier-ignore
            type={"checkbox"}
            id="checkbox"
            label="Non Refundable"
            inline
          />
          <Form.Check // prettier-ignore
            type={"checkbox"}
            id="checkbox"
            label="Lowest Fare"
            inline
          />
        </Form>
        <section id="pricing-container">
<div id="pricing-cards">
		<div className="price-card">

			<div className="price-card--header">
				<h4>NON REFUNDABLE <br/>(LIVE BOOKING)</h4>
				<p style={{margin:"5px "}}>Partially Refundable</p>
			</div>

			<div className="price-card--price">
				<div className="price-card--price-text">
					<div className="price-card--price-number toggle-price-content odometer" data-price-monthly="2,499" data-price-yearly="2,250"> 
                    <Form.Check // prettier-ignore
            type={"radio"}
            id="radio1"
            style={{fontSize:"25px",marginRight:"10px"}}
          />
                    <span>2,250</span></div>
				</div>
				
			</div>

			<div className="price-card--features">

				<ul className="price-card--features--list scrollbar-1">
					<li className="price-card--features--item has-tooltip">Sales & Marketing Platform 
						<div className="tooltip-container"><strong>Sales & Marketing Platform</strong>
							<p>Vendasta's end-to-end sales solution for companies that serve local businesses, and want to grow digital revenue.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Snapshot & Campaigns
						<div className="tooltip-container"><strong>Snapshot & Campaigns</strong>
							<p>Access to award-winning automated needs-assessment and email marketing automation. Unlimited emails sent to unlimited accounts.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Marketplace
						<div className="tooltip-container"><strong>Marketplace</strong>
							<p>Access to world-className resellable products & services, aimed to fulfilling the digital marketing needs of your small & medium sized business clients.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Business Center & Store
						<div className="tooltip-container"><strong>Business Center & Store</strong>
							<p>Portal for your local business clients, featuring your agency’s branding. They can access the products purchased from your Marketplace.</p>
						</div>
					</li>

					
				</ul>
			</div>

			<div className="price-card--mobile-features-toggle"></div>

		</div>
		
		
		<div className="price-card">

		<div className="price-card--header">
				<h4>REFUNDABLE</h4>
				<p style={{margin:"5px "}}>Partially Refundable</p>
			</div>

			<div className="price-card--price">
				<div className="price-card--price-text">
					<div className="price-card--price-number toggle-price-content odometer" data-price-monthly="2,499" data-price-yearly="2,250"> 
                    <Form.Check // prettier-ignore
            type={"radio"}
            id="radio1"
            style={{fontSize:"25px",marginRight:"10px"}}
          />
                    <span>2,250</span></div>
				</div>
				
			</div>


			<div className="price-card--features">
				<ul className="price-card--features--list scrollbar-1">

					<li className="price-card--features--item has-tooltip">Sales & Marketing Platform
						<div className="tooltip-container"><strong>Sales & Marketing Platform</strong>
							<p>Vendasta's end-to-end sales solution for companies that serve local businesses, and want to grow digital revenue.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Snapshot & Campaigns
						<div className="tooltip-container"><strong>Snapshot & Campaigns</strong>
							<p>Access to award-winning automated needs-assessment and email marketing automation. Unlimited emails sent to unlimited accounts.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Marketplace
						<div className="tooltip-container"><strong>Marketplace</strong>
							<p>Access to world-className resellable products & services, aimed to fulfilling the digital marketing needs of your small & medium sized business clients.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Business Center & Store
						<div className="tooltip-container"><strong>Business Center & Store</strong>
							<p>Portal for your local business clients, featuring your agency’s branding. They can access the products purchased from your Marketplace.</p>
						</div>
					</li>

					

				</ul>
			</div>

			<div className="price-card--mobile-features-toggle"></div>

		</div>
        <div className="price-card">

        <div className="price-card--header">
				<h4>SME</h4>
				<p style={{margin:"5px "}}>Partially Refundable</p>
			</div>

			<div className="price-card--price">
				<div className="price-card--price-text">
					<div className="price-card--price-number toggle-price-content odometer" data-price-monthly="2,499" data-price-yearly="2,250"> 
                    <Form.Check // prettier-ignore
            type={"radio"}
            id="radio1"
            style={{fontSize:"25px",marginRight:"10px"}}
          />
                    <span>2,250</span></div>
				</div>
				
			</div>


			<div className="price-card--features">
				<ul className="price-card--features--list scrollbar-1">

					<li className="price-card--features--item has-tooltip">Sales & Marketing Platform
						<div className="tooltip-container"><strong>Sales & Marketing Platform</strong>
							<p>Vendasta's end-to-end sales solution for companies that serve local businesses, and want to grow digital revenue.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Snapshot & Campaigns
						<div className="tooltip-container"><strong>Snapshot & Campaigns</strong>
							<p>Access to award-winning automated needs-assessment and email marketing automation. Unlimited emails sent to unlimited accounts.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Marketplace
						<div className="tooltip-container"><strong>Marketplace</strong>
							<p>Access to world-className resellable products & services, aimed to fulfilling the digital marketing needs of your small & medium sized business clients.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Business Center & Store
						<div className="tooltip-container"><strong>Business Center & Store</strong>
							<p>Portal for your local business clients, featuring your agency’s branding. They can access the products purchased from your Marketplace.</p>
						</div>
					</li>

					

				</ul>
			</div>

			<div className="price-card--mobile-features-toggle"></div>

		</div>
		
		<div className="price-card">

		<div className="price-card--header">
				<h4>SPICEMAX</h4>
				<p style={{margin:"5px "}}>Partially Refundable</p>
			</div>

			<div className="price-card--price">
				<div className="price-card--price-text">
					<div className="price-card--price-number toggle-price-content odometer" data-price-monthly="2,499" data-price-yearly="2,250"> 
                    <Form.Check // prettier-ignore
            type={"radio"}
            id="radio1"
            style={{fontSize:"25px",marginRight:"10px"}}
          />
                    <span>2,250</span></div>
				</div>
			
			</div>


			

			<div className="price-card--features">
				<ul className="price-card--features--list scrollbar-1">

					<li className="price-card--features--item has-tooltip">Sales & Marketing Platform
						<div className="tooltip-container"><strong>Sales & Marketing Platform</strong>
							<p>Vendasta's end-to-end sales solution for companies that serve local businesses, and want to grow digital revenue.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Snapshot & Campaigns
						<div className="tooltip-container"><strong>Snapshot & Campaigns</strong>
							<p>Access to award-winning automated needs-assessment and email marketing automation. Unlimited emails sent to unlimited accounts.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Marketplace
						<div className="tooltip-container"><strong>Marketplace</strong>
							<p>Access to world-className resellable products & services, aimed to fulfilling the digital marketing needs of your small & medium sized business clients.</p>
						</div>
					</li>
					<li className="price-card--features--item has-tooltip">Business Center & Store
						<div className="tooltip-container"><strong>Business Center & Store</strong>
							<p>Portal for your local business clients, featuring your agency’s branding. They can access the products purchased from your Marketplace.</p>
						</div>
					</li>
					

				</ul>
			</div>

			<div className="price-card--mobile-features-toggle"></div>

		</div>
		
	</div>

	

</section>
        </Modal.Body>
        <Modal.Footer className="justicont">
        <div className="modal-tit">
            <div>
               
                <p  style={{fontSize:"15px",marginBottom:"10px",fontWeight:600}}>NON REFUNDABLE (LIVE BOOKING)</p>
                <p style={{fontSize:"20px",color:"#000"}}><b>₹  6000</b></p>
            </div>
            </div>
         <div>
         
         <button type="submit" class="btn text-center btn-danger book round center-block pull-right" style={{height:"40px"}}>BOOK NOW</button>
         </div>
        </Modal.Footer>
      </Modal>
    </>);
}

export default MoreDetails;