import { Col, Container, Row, Table } from "react-bootstrap";
import  './ticket.css'
function Ticket() {
    return (<>
<Container className="mt-3">
    <Row>
        <Col>
        <div className="ticket">
   <div className="placeTime">
      <div className="ticketHeader">
         <p>Your flight from BLR~India→ DEL~India</p>
         <p><i class="fa fa-download" aria-hidden="true"></i> Download <span>&nbsp;&nbsp;</span>|<span>&nbsp;&nbsp;</span> <i class="fa fa-print" aria-hidden="true"></i> Print</p>
      </div>
      <div className="ticketContent">
         <div className="flightName">
            <div className="flightImg"><img src="img/UK.gif" /></div>
            <div className="nameTitleCode">
               <div className="nameTitle"><b>SpiceJet</b></div>
               <div className="nameCode">SG-SG 136</div>
            </div>
         </div>
         <div className="flightDateTime">
            <div className="airportName"><p><b>BLR~India {"(BLR)"}</b></p></div>
            <div className="airportCode"><p>BLR</p></div>
            <div className="terminal"><p>Terminal</p></div>
            <div className="flightTimes"><p><b>Sun, 12 Nov, 2023 05:55</b></p></div>
         </div>
         <div className="flightDateTime">
            <div className="airportName"><p><b>BLR~India {"(BLR)"}</b></p></div>
            <div className="airportCode"><p>BLR</p></div>
            <div className="terminal"><p>Terminal</p></div>
            <div className="flightTimes"><p><b>Sun, 12 Nov, 2023 05:55</b></p></div>
         </div>
         <div className="stop"><p><b>Non Stop</b></p><br/><p>02:30</p></div>
      </div>
   </div>
</div>
<div className="ticket mt-3">
<div className="ticketHeader">
         <p>Passenger Details</p>
      </div>
      <Table className="mb-0"  striped bordered hover>
        <tr>
            <th>Sr. No.</th>
            <th>Passenger Name</th>
            <th>Type</th>
            <th>PNR</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Mr Aditya Narain Mukherjee</td>
            <td>Adult</td>
            <td>HYHBWC</td>
        </tr>
      </Table>
</div>
<div className="ticket mt-3">
<div className="ticketHeader">
         <p>Baggage Information</p>
      </div>
      <Table className="mb-0"  striped bordered hover>
        <tr>
            <th>Type</th>
            <th>Pax</th>
            <th>Cabin</th>
            <th>Check-in
</th>
        </tr>
        <tr>
            <td>Mr Aditya Narain Mukherjee</td>
            <td>Adult</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>1</td>
            <td>Mr Aditya Narain Mukherjee</td>
            <td>Adult</td>
            <td>HYHBWC</td>
        </tr>
      </Table>
</div>


<div className="ticket mt-3">
<div className="ticketHeader">
         <p>Payment Information</p>
      </div>
      <ul style={{listStyle: "disc",marginLeft: "17px"}}>
        <li> You have Paid <b>INR 8500.00</b></li>
        <li>This booking was made by SETURTRIP travel agent with email address info.seturtrip@gmail.com and phone number 9999866662</li>
      </ul>
</div>

<div className="ticket mt-3">
<div className="ticketHeader">
         <p>Agent Information</p>
      </div>
      <h5 style={{padding:"5px"}}><b>Agency Name - SETURTRIP (Arpit Gupta)</b></h5>
      <h5 style={{padding:"5px"}}><b>Phone Number - 9999866662</b></h5>
</div>
        </Col>
    </Row>
    <Row className="mt-3">
      <Col>
      <h3><b>Important Information</b></h3>
      <ul className="mt-3"  style={{listStyle: "disc",marginLeft: "17px"}}>
         <li>
         <b>Special fares / series fares Terms & Conditions :</b>
         {`
          1)This group ticket is 100% Non Refundable, Non Changeable & Non Cancellable. 2)Charged fare is totally
         agreed between "BUYER & SELLER", any issues related to fares thereafter will not be entertained. 3)Check flight & passenger(s) details directly by logging /
         calling to the respective airlines, any dispute with in 24 hours prior to departure will not be entertained. 4)No updates will be shared from our end in respect to
         flight cancellation / changes in timings, "BUYER" had to check directly with the respective airlines before departure. 5)As per government directives, Web Checkin is mandatory for all passengers before the scheduled departure of their domestic flight. Charges apply*
         `}
         </li>
         <li>
         <b>Check-in Time :</b>
         {`
          Passenger to report 2 hours before departure. Check-in procedure and baggage drop will close 1 hour before departure.
          `}
         </li>
         <li>
         <b>Baggage information :</b>
         {`
          Carry no more than 1 check-in baggage and 1 hand baggage per passenger. Additional pieces of Baggage will be subject to additional
          charges per piece in addition to the excess baggage charges. Carry no more than 1 check-in baggage and 1 hand baggage per passenger. Additional pieces of
          Baggage will be subject to additional charges per piece in addition to the excess baggage charges.
          `}
         </li>
         <li>
         <b>Mandatory check-list for passengers :</b>
         {`
          1. Vaccination Requirements: None. 2. COVID test requirements : None. Negative RT-PCR test report is NOT required to
          enter Delhi. 3. Pre-registration or e-Pass requirements : Download the Aarogya Setu App 4. Quarantine Guidelines : Those who test positive during the random
          on-arrival tests will be quarantined at home or hospital for 10 days. 5. Destination Restrictions : None. 6. Remember to web check-in before arriving at the airport;
          carry a printed or softcopy of the boarding pass.7. Please reach at least 2 hours prior to flight departure.8. The latest DGCA guidelines state that it is compulsory
          to wear a mask that covers the nose and mouth properly while at the airport and on the flight. Any lapse might result in de-boarding.9. Remember to download
          the baggage tag(s) and affix it on your bag(s). 1. Vaccination Requirements: None. 2. COVID test requirements : None. Negative RT-PCR test report is NOT
          required to enter Delhi. 3. Pre-registration or e-Pass requirements : Download the Aarogya Setu App 4. Quarantine Guidelines : Those who test positive during the
          random on-arrival tests will be quarantined at home or hospital for 10 days. 5. Destination Restrictions : None. 6. Remember to web check-in before arriving at
          the airport; carry a printed or soft copy of the boarding pass.7. Please reach at least 2 hours prior to flight departure.8. The latest DGCA guidelines state that it is
          compulsory to wear a mask that coversthe nose and mouth properly while at the airport and on the flight. Any lapse might result in de-boarding.9. Remember
          to download the baggage tag(s) and affix it on your bag(s).
          
          `}
         </li>
         <li>
         <b>State Guidelines :</b>
         {`
          Check the detailed list of travel guidelines issued by Indian States and UTs. Check the detailed list of travel guidelines issued by Indian States
          and UTs.
          
          `}
         </li>
         <li>
         <b>Valid ID proof needed :</b>
         {`
          Carry a valid photo identification proof (Driver Licence, Aadhar Card, Pan Card or any other Government recognised photo
            identification)
          `}
         </li>
         <li>
         <b>DGCA passenger charter :</b>
         {`
          Please refer to passenger charter by clicking Here
          `}
         </li>
         <li>
         <b>Beware of fraudsters :</b>
         {`
          Please do not share your personal banking and security details like passwords, CVV, etc. with any third person.
          `}
         </li>
      </ul>
      </Col>
    </Row>
</Container>



    </>);
}

export default Ticket;