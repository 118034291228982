import FlightResult from "./flightSearch";
import ResultFilter from "./resultFilter";
import Filter from "./searchfilter";
import "./style.css"

function RoundTrip() {
    return (<>
    <Filter/>
    <ResultFilter/>
    <FlightResult/>
    </>);
}

export default RoundTrip;