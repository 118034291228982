import "./style.css"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from "react";
function BookFlightTicket() {
	const [child,setChild] = useState(0)
    return (<>
	
    <section class="main_slider_area">
	<div id="myCarousel" class="carousel slide mb-6" data-bs-ride="carousel">
   
    <div class="carousel-inner">
      <div class="carousel-item active">
	  <img src="img/home-slider/slider1.jpg" alt="Los Angeles" style={{width:"100%"}}/>
      </div>
      <div class="carousel-item">
	  <img src="img/home-slider/slider2.jpg" alt="Los Angeles" style={{width:"100%"}}/>
      </div>
      <div class="carousel-item">
	  <img src="img/home-slider/slider3.jpg" alt="Los Angeles" style={{width:"100%"}}/>
      </div>
    </div>
 
  </div>
        	{/* <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
		   
			    <div class="carousel-inner">
			    
			      <div class="item active">
			        <img src="img/home-slider/slider1.jpg" alt="Los Angeles" style={{width:"100%"}}/>
			       
			      </div>

			      <div class="item">
			        <img src="img/home-slider/slider2.jpg" alt="Chicago"  className="w-100"  style={{width:"100%"}}/>
			      </div>
			    
			      <div class="item">
			        <img src="img/home-slider/slider3.jpg" alt="New York"  className="w-100"  style={{width:"100%"}}/>
			      </div>
			  
			    </div>
		  	</div> */}
        </section> 
        <section id="main_banner_form">
        	<div className="col-md-5 slide-form">
				 	<div className="banner_form_start_now formHeight">
				         
	                        <div className="slider_text_box">
	                        	<div className="panel panel-primary">
	                <div className="panel-heading">
	                	<h3>Book Your Flight</h3>
	                    <span className="slider-tab">
	                    
	                        <ul className="nav panel-tabs mainTab">
	                            <li className="active"><a href="#tab1" data-toggle="tab">Flight</a></li>
	                            <li><a href="#tab2" data-toggle="tab">Hotel</a></li>
	                            <li><a href="#tab3" data-toggle="tab">Tour</a></li>
	                        </ul>
	                    </span>
	                </div>
	                <div className="panel-body">
	                    <div className="tab-content">

	                        <div className="tab-pane active" id="tab1">
	                        	<h3>Book Domestic & International Flight</h3>
	                        	<form action="">
	                        		<div className="panel-heading" id="iner_new_tabstart">
					                    <span className="slider-tab">
					                      
					                        <ul className="nav panel-tabs" style={{justifyContent:"center"}}>
					                            <li className="active"><a href="#tab4" data-toggle="tab">One way</a></li>
					                            <li><a href="#tab5" data-toggle="tab">Round Trip</a></li>
					                            <li><a href="#tab6" data-toggle="tab">Special Round Trip</a></li>
					                            <li><a href="#tab7" data-toggle="tab">Multi City</a></li>
					                        </ul>
					                    </span>
<hr/>
					                    <div className="panel-body pt-0">
					                    	<div className="tab-content">
					                        	<div className="tab-pane active" id="tab4">
					                        		<div className="row">
														<div className="col-md-4 col-sm-4 col-xs-4">
															<div className="form-group">
															<label>From:</label>
															<input type="text" name="" className="" placeholder="From : Type City"/>
															</div>
														</div>
														<div className="col-md-4 col-sm-4 col-xs-4">
															<div className="form-group">
															<label>To :</label>
															<input type="text" name="" className="" placeholder="To : Type City"/>
															</div>
														</div>
														<div className="col-md-4 col-sm-4 col-xs-4">
														  <div className="form-group">
														  	<label>Depart on:</label>
														  	<input type="date" id="datepicker" className="new-form-cls" placeholder="dd/mm/yyyy..."/>
														  </div>
														</div>
													</div>

													<div className="row">
														
													</div>

													<div className="row">
														<div className="col-md-4 col-sm-4 col-xs-4">
														  <div className="form-group">
														  	<label>Adults:</label>
														    <input type="number" name="" className="" placeholder="0"/>
														  </div>
														</div>
														<div className="col-md-4 col-sm-4 col-xs-4">
															<div className="form-group">
															  	<label>Childs(3-10):</label>
															    <input type="number" name="" className="" placeholder="0"/>
															</div>
														</div>
														<div className="col-md-4 col-sm-4 col-xs-4">
														   <div className="form-group">
															  	<label>Infant(0-2):</label>
															    <input type="number" name="" className="" placeholder="0"/>
														    </div>
														</div>
													</div>

													<div className="row">
														<div className="col-md-6 col-sm-6 col-xs-6">
															  <div className="form-group">
															  	<label>Class:</label>
															    <select name="carlist" form="carform" className="main_select">
																  <option value="Select">Economy</option>
																  <option value="saab">Saab</option>
																  <option value="opel">Opel</option>
																  <option value="audi">Audi</option>
																</select>	
															  </div>
														</div>
														<div className="col-md-6 col-sm-6 col-xs-6">
														<div className="form-group">
														  	<label>Airline Type:</label>
														    <select name="carlist" form="carform" className="main_select">
															  <option value="Select">All</option>
															  <option value="saab">Saab</option>
															  <option value="opel">Opel</option>
															  <option value="audi">Audi</option>
															</select>	
														  </div>
														</div>
													</div>

													
					                        	</div>


					                        	<div className="tab-pane" id="tab5">
					                        		<div className="row">
														<div className="col-md-6 col-sm-6 col-xs-6">
															<div className="form-group">
															<label>From:</label>
															<input type="text" name="" className="" placeholder="From : Type City"/>
															</div>
														</div>
														<div className="col-md-6 col-sm-6 col-xs-6">
															<div className="form-group">
															<label>To :</label>
															<input type="text" name="" className="" placeholder="To : Type City"/>
															</div>
														</div>
													</div>

													<div className="row">
														<div className="col-md-6 col-sm-6 col-xs-6">
														  <div className="form-group">
														  	<label>Depart on:</label>
														  	<input type="text" id="datepicker" className="new-form-cls" placeholder="dd/mm/yyyy..."/>
														  </div>
														</div>
														<div className="col-md-6 col-sm-6 col-xs-6">
															  <div className="form-group">
															  	<label>Return on:</label>
															  	<input type="text" id="datepicker2" className="new-form-cls" placeholder="dd/mm/yyyy..."/>
															  </div>
														</div>
													</div>

													<div className="row">
														<div className="col-md-4 col-sm-4 col-xs-4">
														  <div className="form-group">
														  	<label>Adults:</label>
														    <input type="number" name="" className="" placeholder="0"/>
														  </div>
														</div>
														<div className="col-md-4 col-sm-4 col-xs-4">
															<div className="form-group">
															  	<label>Childs(3-10):</label>
															    <input type="number" name="" className="" placeholder="0"/>
															</div>
														</div>
														<div className="col-md-4 col-sm-4 col-xs-4">
														   <div className="form-group">
															  	<label>Infant(0-2):</label>
															    <input type="number" name="" className="" placeholder="0"/>
														    </div>
														</div>
													</div>

													<div className="row">
														<div className="col-md-6 col-sm-6 col-xs-6">
															  <div className="form-group">
															  	<label>Class:</label>
															    <select name="carlist" form="carform" className="main_select">
																  <option value="Select">Economy</option>
																  <option value="saab">Saab</option>
																  <option value="opel">Opel</option>
																  <option value="audi">Audi</option>
																</select>	
															  </div>
														</div>
														<div className="col-md-6 col-sm-6 col-xs-6">
														<div className="form-group">
														  	<label>Airline Type:</label>
														    <select name="carlist" form="carform" className="main_select">
															  <option value="Select">All</option>
															  <option value="saab">Saab</option>
															  <option value="opel">Opel</option>
															  <option value="audi">Audi</option>
															</select>	
														  </div>
														</div>
													</div>
								 
												</div>

					                    	</div>
					                	</div>
					                </div>
					                <div className="col-md-12">
					                 	<button type="submit" className="btn btn-default submit_btns">Search</button>
					             	</div>
								</form>
	                        </div>

	                        <div className="tab-pane" id="tab2">
	                        	<h3>Book Domestic & International Hotels</h3>
	                        	<form action="">
	                        		<div className="row">
	                        			<div className="col-md-4 col-sm-4">
								  <div className="form-group">
								   <input type="text" name="" className="" placeholder="City"/>
								  </div>
								</div>
								<div className="col-md-4 col-sm-4">
								  <div className="form-group">
								  	<input type="date" id="datepicker3" className="new-form-cls" placeholder="Travel Date"/>
								  </div>
								</div>
								<div className="col-md-4 col-sm-4">
								  <div className="form-group">
								  	<input type="text" id="datepicker4" className="new-form-cls" placeholder="Number of Night"/>
								  </div>
								</div>
								</div>
								<div className="row">
									
								
								</div>
								<div className="row">
									<div className="col-md-4 col-sm-12">
								  <div className="form-group">
								  	<label>Rooms:</label>
								    <select name="carlist" form="carform" className="main_select">
									  <option value="Select">-</option>
									  <option value="saab">1</option>
									  <option value="opel">2</option>
									  <option value="audi">3</option>
									</select>
								  </div>
								</div>
								<div className="col-md-4 col-sm-12">
								   <div className="form-group">
								  	<label>Adults(12+)</label>
								    <select name="carlist" form="carform" className="main_select">
									  <option value="Select">1</option>
									  <option value="saab">1</option>
									  <option value="opel">2</option>
									  <option value="audi">3</option>
									</select>
								  </div>
								</div>
								<div className="col-md-4 col-sm-12">
								   <div className="form-group">
								  	<label>Children(0-12)</label>
								    <select name="carlist" form="carform" className="main_select" onChange={(e)=>setChild(e.target.value)}>
									  <option value="0">0</option>
									  <option value="1">1</option>
									  <option value="2">2</option>
									  <option value="3">3</option>
									</select>
								  </div>
								</div>
								<div className="col-md-12 col-sm-12">
								<div className="row">
								{
  child > 0 ? (
    Array.from({ length: child }, (_, index) => (
      <div className="col-md-4 col-sm-12" key={index}>
        <label>{`Children ${index + 1}`}</label>
        <select name="carlist" form="carform" className="main_select">
		<option value="Select">0</option>
									  <option value="saab">1</option>
									  <option value="opel">2</option>
									  <option value="audi">3</option>
									  <option value="saab">4</option>
									  <option value="opel">5</option>
									  <option value="audi">6</option>
									  <option value="saab">7</option>
									  <option value="opel">8</option>
									  <option value="audi">9</option>
									  <option value="saab">10</option>
									  <option value="opel">11</option>
									  <option value="audi">12</option>
									  <option value="opel">13</option>
									  <option value="audi">14</option>
									  <option value="saab">15</option>
									  <option value="opel">16</option>
									  <option value="audi">17</option>
          {/* Add more options as needed */}
        </select>
      </div>
    ))
  ) : null
}
								</div>
								</div>
								</div>
								  <button type="submit" className="btn btn-default submit_btns">Search</button>
								</form>
	                        </div>
	                        <div className="tab-pane" id="tab3">
	                        	<h3>Book Domestic & International Tours</h3>
	                        	<form action="">
	                        		<div className="row">
	                        			<div className="panel-heading" id="iner_new_tabstart">
					                    <span className="slider-tab">
					                        
					                        <ul className="nav panel-tabs">
					                            <li className="active"><a href="#tab4" data-toggle="tab">Domestic</a></li>
					                            <li><a href="#tab5" data-toggle="tab">International</a></li>
					                        </ul>
					                    </span>
					                </div>
								</div>
								<div className="row">
									<div className="col-md-12 col-sm-12">
								  <div className="form-group">
								  	<select name="carlist" form="carform" className="main_select">
									  <option value="Select">Select</option>
									  <option value="saab">Saab</option>
									  <option value="opel">Opel</option>
									  <option value="audi">Audi</option>
									</select>	
								  </div>
								</div>
								</div>
								<div className="row">
									<div className="col-md-6 col-sm-12">
								  <div className="form-group">
								  	<label>Depart on:</label>
								  	<input type="text" id="datepicker5" className="new-form-cls" placeholder="dd/mm/yyyy..."/>
								  </div>
								</div>
								<div className="col-md-6 col-sm-12">
								  <div className="form-group">
								  	<label>Return on:</label>
								  	<input type="text" id="datepicker6" className="new-form-cls" placeholder="dd/mm/yyyy..."/>
								  </div>
								</div>
								</div>
								  <button type="submit" className="btn btn-default submit_btns">Search</button>
								</form>
	                        </div>
	                    </div>
	                </div>
	            	</div>
        			</div>
                    </div>
            </div>
		</section>
    </>);
}

export default BookFlightTicket;