import { useState } from "react";

function Filter() {
	const [child,setChild] = useState(0)
    return (<>
    <section id="start_body_area">
        	<div className="container">
        		<div className="col-md-12">
        			<div className="main_search_form-area" id="new_hotels_page">
        				<h3>Modify Your Search</h3>
        				
								  <div className="form-group" >
								  	<label>DESTINATION</label>
								   <input type="text" name="" className="" placeholder="From : Type City"/>
								  </div>

								  <div className="form-group">
								  	<label>CHECK IN:</label>
								  	<input type="text" id="datepicker" className="new-form-cls" placeholder="dd/mm/yyyy..."/>
								  </div>

								  <div className="form-group">
								  	<label>CHECK OUT:</label>
								  	<input type="text" id="datepicker2" className="new-form-cls" placeholder="dd/mm/yyyy..."/>
							  	  </div>

									<div className="form-group">
										  	<label>Room:</label>
										    <select name="carlist" form="carform" className="main_select">
											  <option value="137" selected="selected">1</option>
											 <option value="165138">2</option>
											 <option value="164981">3</option>
											 <option value="165021">4</option>
											 <option value="165038">5</option>
											 <option value="165167">6</option>
											 <option value="165168">7</option>
											 <option value="165177">8</option>
											 <option value="-1">9</option>
											</select>	
										  </div>
										  <div className="form-group">
										  	<label>Hotel Type:</label>
										    <select name="carlist" form="carform" className="main_select">
											  <option value="137" selected="selected">1</option>
											 <option value="165138">2</option>
											 <option value="164981">3</option>
											 <option value="165021">4</option>
											 <option value="165038">5</option>
											</select>	
										  </div>
										  <div className="form-group">
										  	<label>Adult:</label>
										    <select name="carlist" form="carform" className="main_select">
											  <option value="137" selected="selected">1</option>
											 <option value="165138">2</option>
											 <option value="164981">3</option>
											 <option value="165021">4</option>
											 <option value="165038">5</option>
											 <option value="165167">6</option>
											 <option value="165168">7</option>
											 <option value="165177">8</option>
											 <option value="-1">9</option>
											</select>	
										  </div>

										  <div className="form-group">
										  	<label>Child:</label>
										    <select name="carlist" form="carform" className="main_select" onChange={(e)=>setChild(e.target.value)}>
											  <option value="0" selected="selected">0</option>
											  <option value="2">1</option>
											 <option value="2">2</option>
											 <option value="3">3</option>
											 <option value="4">4</option>
											 <option value="5">5</option>
											 <option value="6">6</option>
											</select>	
										  </div>
										 
										  {
  child > 0 ? (
    Array.from({ length: child }, (_, index) => (
      <div className="form-group" key={index}>
        <label>{`Children ${index + 1}`}</label>
        <select name="carlist" form="carform" className="main_select">
		<option value="Select">0</option>
									  <option value="saab">1</option>
									  <option value="opel">2</option>
									  <option value="audi">3</option>
									  <option value="saab">4</option>
									  <option value="opel">5</option>
									  <option value="audi">6</option>
									  <option value="saab">7</option>
									  <option value="opel">8</option>
									  <option value="audi">9</option>
									  <option value="saab">10</option>
									  <option value="opel">11</option>
									  <option value="audi">12</option>
									  <option value="opel">13</option>
									  <option value="audi">14</option>
									  <option value="saab">15</option>
									  <option value="opel">16</option>
									  <option value="audi">17</option>
          {/* Add more options as needed */}
        </select>
      </div>
    ))
  ) : null
}
										  <div className="form-group" id="div_biger">
											<button type="submit" className="btn btn-default submit_btns">Search Hotels</button>
										  </div>
								
        			</div>
        		</div>
        	</div>
        </section>
    </>);
}

export default Filter