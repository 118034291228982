import { useEffect, useRef } from "react";

function PackageSlider() {
    const ref = useRef(false);

    useEffect(() => {
      if (!ref.current) {
        ref.current = true;
        var script = document.createElement("script");
        script.innerHTML = `
        $('.owl-carousel').owlCarousel({
            loop:true,
            margin:40,
            nav:true,
            autoplay:1000,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:3
                },
                1000:{
                    items:6
                }
            }
        })
        `;
        document.body.appendChild(script);
      }
    }, []);
    
    return (<>
    <section id="gallery-slidermain">
        	 <div className="container">
	        	<div className="explor_title row m0">
	                    <div className="title-text">
	                        <div className="left_ex_title"> 
	                            <h2>Top Holiday <span>Destinations</span></h2>
	                        </div>
	                    </div>
	                </div>
	            <div className="container">
	                  <div className="owl-carousel owl-theme">
	                  <div className="item"><img src="img/gallery/1.jpg" alt=""/><h4>Dubai</h4></div>
	                  <div className="item"><img src="img/gallery/2.jpg" alt=""/><h4>Goa</h4></div>
	                  <div className="item"><img src="img/gallery/3.jpg" alt=""/><h4>Kashmir</h4></div>
	                  <div className="item"><img src="img/gallery/4.jpg" alt=""/><h4>Kerala</h4></div>
	                  <div className="item"><img src="img/gallery/5.jpg" alt=""/><h4>Singapore</h4></div>
	                  <div className="item"><img src="img/gallery/6.jpg" alt=""/><h4>Thailand</h4></div>
	                  <div className="item"><img src="img/gallery/7.jpg" alt=""/><h4>Kashmir</h4></div>
	                  </div>
	           </div>
       	    </div>
        </section>
    </>);
}

export default PackageSlider;