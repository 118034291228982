import { useEffect, useRef } from "react";

function FlightResult() {
    const ref = useRef(false)
    useEffect(()=>{
        if(!ref.current){
            ref.current = true
            var script = document.createElement("script")
            script.innerHTML = `$('.owl-carousel').owlCarousel({
                loop:true,
                margin:40,
                nav:true,
                autoplay:1000,
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:3
                    },
                    1000:{
                        items:6
                    }
                }
            })`
            document.body.appendChild(script)
        }
        
        
    },[])
    return (<>
    <section id="show_flights" class="hotel_special_page">
			<div class="container">
				<div class="col-md-12">
					<div class="slider_text_box" id="hotels_searching">
                        	<div class="panel panel-primary">
				                <div class="panel-heading">
				                    <span class="slider-tab">
				                      
				                        <ul class="nav panel-tabs">
				                            <li class="active"><a href="#tab1" data-toggle="tab"><span class="fa fa-bed"></span>All Hotels :<span>1740</span> </a></li>
				                            <li><a href="#tab2" data-toggle="tab"><span class="fa fa-compress"></span> Deals :<span>1740</span> </a></li>
				                            <li><a href="#tab3" data-toggle="tab"><span class="fa fa-paypal"></span> Pay@Hotel :<span>25</span> </a></li>
				                        </ul>
				                    </span>
				                </div>
			                <div class="panel-body">
			                    <div class="tab-content">
			                        <div class="tab-pane active" id="tab1">
			                        	<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<div className="pm-set" >
															<p >Starting From</p>
			                                                <div >
															<h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
															</div>
															</div>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel   col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
			                        </div>
			                        <div class="tab-pane" id="tab2">
			                        	<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-2.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
			                        </div>
			                        <div class="tab-pane" id="tab3">
			                        	<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-3.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
                    					<div class="box">
			                                <div class="row">
			                                	<div class="flightco flightcoHotel col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0px",margin:"0px"}}>
			                                        <img src="img/hotels/room-l-1.jpg" class="img-responsive" alt=""/>
			                                     </div>
			                                    <div class="col-md-7 col-sm-7 col-xs-12 f1">
			                                        <div class="main_hotel_data">
			                                        	<h3><a href="#">WelcomHotel Dwarka, New Delhi - ITC Hotel Group</a></h3>
			                                        	<p>Plot no 3, Sector 10, 205 Delhi Road District Center, Dwarka,110075,Delhi,New Delhi,India</p>
			                                        	<div class="facilities">
			                                        		<ul>
			                                        			<li><i class="fa fa-wifi" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-cutlery" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-coffee" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
			                                        			<li><i class="fa fa-bath" aria-hidden="true"></i></li>
			                                        		</ul>
			                                        	</div>
			                                        	<div class="form-group" id="rating-ability-wrapper">
														    <label class="control-label" for="rating">
														    <span class="field-label-info"></span>
														    <input type="hidden" id="selected_rating" name="selected_rating" value="" required="required"/>
														    </label>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="1" id="rating-star-1">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="2" id="rating-star-2">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="3" id="rating-star-3">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="4" id="rating-star-4">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														    <button type="button" class="btnrating btn btn-default btn-lg" data-attr="5" id="rating-star-5">
														        <i class="fa fa-star" aria-hidden="true"></i>
														    </button>
														</div>
														<div class="reviews_color"><i class="fa fa-smile-o" aria-hidden="true"></i><a href="#">1125 Reviews</a></div>
			                                        </div>
			                                    </div>
			                                    <div class="col-md-3 col-sm-3 col-xs-12 f2 text-center booknow">
			                                        <div class="row">
			                                            <div class="col-md-12 col-sm-12">
			                                            	<p>Starting From</p>
			                                                <h4><span style={{fontSize: "14px"}}>Rs. 3,548</span></h4><b>Per room/night</b>
			                                                <p>
					                    					<button type="submit" class="btn text-center btn-danger book round center-block">Select Rooms</button>
					                						</p>
			                            				</div>
			                        				</div>
					                			</div>
					                    	</div>
		                    				<div class="clearfix"></div>
                    					</div>
			                        </div>
			                    </div>
			                </div>
            				</div>
                        </div>
				</div>

			</div>
		</section>
    </>);
}

export default FlightResult;