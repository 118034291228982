import { useEffect, useRef } from "react";

function FlightResult() {
    const ref = useRef(false)
    useEffect(()=>{
        if(!ref.current){
            ref.current = true
            var script = document.createElement("script")
            script.innerHTML = `$('.owl-carousel').owlCarousel({
                loop:true,
                margin:40,
                nav:true,
                autoplay:1000,
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:3
                    },
                    1000:{
                        items:6
                    }
                }
            })`
            document.body.appendChild(script)
        }
        
        
    },[])
    return (<>
    <section id="show_flights" class="special-round-trip">
			<div class="container">
				<div class="col-md-12 top-container">
					<div class="selected_flight"  id="myHeader">
						<div class="fltTcktVoucher fl width100 content" id="fltTcktVoucher">
							<div class="col-md-2"></div>
							<div class="col-md-6 col-sm-8 col-xs-12">
								<div class="col-md-1 hidden-sm padT5">
									<div class="fl">
										<img src="img/barcode.gif"/>
									</div>
								</div>
								<div class="col-md-11 col-sm-11">
									<div class="fl width100 ico12 padT10 padB20 padL20">New Delhi to Goa Sun, 21 Jul</div>
									<div class="brdrDashRt fl width100 marginB20">
										<div class="col-md-3 col-sm-3 col-xs-2 marginTM5">
											<span class="db txtCenter">
												<img src="img/TG.gif" alt=""/>
											</span>
											<span class="db txtCenter greyLt ico10 padR10">Spicejet</span>
										</div>
										<div class="col-md-9 col-sm-7 col-xs-7  padLR0 brdRight">
											<div class="fl width100">
												<div class="col-md-4 col-sm-4 col-xs-4">
													<span class="ico20 db fn">11:30</span>
													<span class="ico13 db padT5">DEL</span>
												</div>
												<div class="col-md-4 col-sm-5 col-xs-5 padT5">
													<div class="ico13 hidden-sm fb padB5">2h 35m</div>
													<span class="ico12 db hidden-sm fn grey padT3">(9Non-Stop)</span>
												</div>
												<div class="col-md-4 col-sm-3 col-xs-3 padLR0">
													<span class="ico20 db fn">14:05</span>
													<span class="ico13 db padT5">GOI</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="fr col-md-2 col-sm-4 padT20">
								<div class="padR10 padT5 fr summryPrc">
									<div class="fr clr posRel padB5 padT5">
										<span class="fr">
											<i class="fa fa-inr" aria-hidden="true">6,393</i>
											<i class="icon-rupee fr ico16 padT3"></i>
										</span>
									</div>
									<div class="db">
										<input type="button" value="BOOK" class="button orange fr"/>
									</div>
								</div>
							</div>
							<div class="col-md-2"></div>
						</div>
					</div>
				</div>
				<div class="clearfix"></div>
				<div class="col-md-12">
					<div class="row">
						<div class="col-md-8 col-sm-8">
							<div class="cheapest_flights">
								<h4>Cheapest Flights</h4>
								<div class="owl-carousel owl-theme">
				                  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
								  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
								  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
								  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  </div>
							</div>
						</div>
						<div class="col-md-4 col-sm-4">
							<div class="flightPromoCont"><div> <div class="u_inlineblk u_font12">Get upto <span class="currency INR u_clViaRed"></span> <b class="amt u_clViaRed u_font16">7000</b> Off* on International flights</div><div class="u_textL u_fontW400 u_marT8 u_font12">Use code <b class="u_clViaGreen u_txtDecoNone u_padR10 u_padL5">VIAINTL</b></div><div class="u_textL u_fontW300 u_font12 u_marT8 u_marB5"><a href="#" target="_blank"> <span class="u_textDecoUL u_textCapitalize">terms apply</span></a></div></div></div>
						</div>
					</div>
				</div>	
				<div class="col-md-12" id="main-special-round-trip">	
					<div class="flight_listing_start">
						<div class="box">
                                <div class="row">
                                	<div class="flightco col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0",margin:"0"}}>
                                        <img src="img/TG.gif" alt="UK"/>
                                        <p style={{margin:"5px 0px 0px"}}>Thai Airways International</p>
                                        <span>TG-316</span>
                                     </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 f1">
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                    </div>	
                                    <div class="col-md-2 col-sm-2 col-xs-12 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <h4><span  style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                                <p>
		                    					<button type="submit" class="btn text-center btn-danger book round center-block pull-right" onclick="return Details('0$207277|54|58UTDO','e7028d33-06a6-44f0-93c5-644b94adfc8a','BS');">BOOK NOW</button>
		                						</p>
                            				</div>
                        				</div>
		                			</div>
		                    	</div>
		                    	<div class="clearfix"></div>
                    	</div>

                    	<div class="box">
                                <div class="row">
                                	<div class="flightco col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0",margin:"0"}}>
                                        <img src="img/TG.gif" alt="UK"/>
                                        <p style={{margin:"5px 0px 0px"}}>Thai Airways International</p>
                                        <span>TG-316</span>
                                     </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 f1">
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                    </div>	
                                    <div class="col-md-2 col-sm-2 col-xs-12 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <h4><span  style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                                <p>
		                    					<button type="submit" class="btn text-center btn-danger book round center-block pull-right" onclick="return Details('0$207277|54|58UTDO','e7028d33-06a6-44f0-93c5-644b94adfc8a','BS');">BOOK NOW</button>
		                						</p>
                            				</div>
                        				</div>
		                			</div>
		                    	</div>
		                    	<div class="clearfix"></div>
                    	</div>

                    	<div class="box">
                                <div class="row">
                                	<div class="flightco col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0",margin:"0"}}>
                                        <img src="img/TG.gif" alt="UK"/>
                                        <p style={{margin:"5px 0px 0px"}}>Thai Airways International</p>
                                        <span>TG-316</span>
                                     </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 f1">
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                    </div>	
                                    <div class="col-md-2 col-sm-2 col-xs-12 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <h4><span  style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                                <p>
		                    					<button type="submit" class="btn text-center btn-danger book round center-block pull-right" onclick="return Details('0$207277|54|58UTDO','e7028d33-06a6-44f0-93c5-644b94adfc8a','BS');">BOOK NOW</button>
		                						</p>
                            				</div>
                        				</div>
		                			</div>
		                    	</div>
		                    	<div class="clearfix"></div>
                    	</div>

                    	<div class="box">
                                <div class="row">
                                	<div class="flightco col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0",margin:"0"}}>
                                        <img src="img/TG.gif" alt="UK"/>
                                        <p style={{margin:"5px 0px 0px"}}>Thai Airways International</p>
                                        <span>TG-316</span>
                                     </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 f1">
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                    </div>	
                                    <div class="col-md-2 col-sm-2 col-xs-12 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <h4><span  style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                                <p>
		                    					<button type="submit" class="btn text-center btn-danger book round center-block pull-right" onclick="return Details('0$207277|54|58UTDO','e7028d33-06a6-44f0-93c5-644b94adfc8a','BS');">BOOK NOW</button>
		                						</p>
                            				</div>
                        				</div>
		                			</div>
		                    	</div>
		                    	<div class="clearfix"></div>
                    	</div>

                    	<div class="box">
                                <div class="row">
                                	<div class="flightco col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0",margin:"0"}}>
                                        <img src="img/TG.gif" alt="UK"/>
                                        <p style={{margin:"5px 0px 0px"}}>Thai Airways International</p>
                                        <span>TG-316</span>
                                     </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 f1">
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                    </div>	
                                    <div class="col-md-2 col-sm-2 col-xs-12 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <h4><span  style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                                <p>
		                    					<button type="submit" class="btn text-center btn-danger book round center-block pull-right" onclick="return Details('0$207277|54|58UTDO','e7028d33-06a6-44f0-93c5-644b94adfc8a','BS');">BOOK NOW</button>
		                						</p>
                            				</div>
                        				</div>
		                			</div>
		                    	</div>
		                    	<div class="clearfix"></div>
                    	</div>

                    	<div class="box">
                                <div class="row">
                                	<div class="flightco col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0",margin:"0"}}>
                                        <img src="img/TG.gif" alt="UK"/>
                                        <p style={{margin:"5px 0px 0px"}}>Thai Airways International</p>
                                        <span>TG-316</span>
                                     </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 f1">
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                    </div>	
                                    <div class="col-md-2 col-sm-2 col-xs-12 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <h4><span  style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                                <p>
		                    					<button type="submit" class="btn text-center btn-danger book round center-block pull-right" onclick="return Details('0$207277|54|58UTDO','e7028d33-06a6-44f0-93c5-644b94adfc8a','BS');">BOOK NOW</button>
		                						</p>
                            				</div>
                        				</div>
		                			</div>
		                    	</div>
		                    	<div class="clearfix"></div>
                    	</div>

                    	<div class="box">
                                <div class="row">
                                	<div class="flightco col-md-2 col-sm-2 col-xs-12 text-center" style={{paddingTop:"0",margin:"0"}}>
                                        <img src="img/TG.gif" alt="UK"/>
                                        <p style={{margin:"5px 0px 0px"}}>Thai Airways International</p>
                                        <span>TG-316</span>
                                     </div>
                                    <div class="col-md-8 col-sm-8 col-xs-12 f1">
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="row">
                                        	<div class="col-md-1 col-sm-1 col-xs-12">
                                        		<div class="check_btn">
                                        			<input type="radio"/>
                                        		</div>
                                        	</div>	

                                            <div class="col-md-2 col-sm-2 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-xs-12">
                                                <div class="duration">
                                                	<img src="img/one-way.png" alt="takeoff" class="img-responsive"/>
                                                    <h4>04h 25m | 0 Stop</h4>
                                					<a data-toggle="collapse" href="#" style={{background:"transparent",padding:"0px 15px"}} aria-expanded="false">
                                            		<span class="more-higl"> 2 more timing option(s)</span>
                                        			</a>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">DELHI</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>16 May 23:30</p>
                                            </div>
                                        </div>
                                    </div>	
                                    <div class="col-md-2 col-sm-2 col-xs-12 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <h4><span  style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                                <p>
		                    					<button type="submit" class="btn text-center btn-danger book round center-block pull-right" onclick="return Details('0$207277|54|58UTDO','e7028d33-06a6-44f0-93c5-644b94adfc8a','BS');">BOOK NOW</button>
		                						</p>
                            				</div>
                        				</div>
		                			</div>
		                    	</div>
		                    	<div class="clearfix"></div>
                    	</div>
                    	





					</div>
				</div>		
			</div>
		</section>
    </>);
}

export default FlightResult;