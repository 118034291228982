import BookFlightTicket from "../../component/bookFightTicket/bookFightTicket";
import PackageList from "../../component/packageCard/packageList";
import PackageSlider from "../../component/packageSlider/packageSlider";
import Testimonials from "../../component/testimonials/testimonials";
import './style.css'

function Home() {
    return (<>
	
    <BookFlightTicket/>
    <div className="threePointHighlight">
				<div className="container ptsCont">
					<div className="points">
						<div className="highlightLogo"><i className="fa fa-star" aria-hidden="true"></i> </div>
						<div  className="starLine">
							Lowest Budget Airline
							<br/>
							<span className="bold">Fare Guarantee </span>
						</div>
					</div>
					<div className="points">
						<div className="highlightLogo"><i className="fa fa-star" aria-hidden="true"></i> </div>
						<div  className="starLine">
							TEasy Book In <span className="bold"></span>
							<br/>
							<span className="bold">just 3 Steps</span>
							
						</div>
					</div>
					<div className="points">
						<div className="highlightLogo"><i className="fa fa-star" aria-hidden="true"></i> </div>
						<div  className="starLine">
							24x365 Days
							<br/>
							<span className="bold">Free Phone Assistance</span>
						</div>
					</div>
					<div className="points">
						<div className="highlightLogo"><i className="fa fa-star" aria-hidden="true"></i> </div>
						<div className="starLine">
							24x365 Days
							<br/>
							<span className="bold">Free Phone Assistance</span>
						</div>
					</div>
				</div>
		</div>
        <PackageList/>
        <PackageSlider/>
        <Testimonials/>
    </>);
}

export default Home;