import logo from './logo.svg';
import './App.css';
import Header from './header/header';
import Footer from './footer/footer';
import Home from './page/home/home';
import { Outlet } from 'react-router-dom';

function App() {
  return (
   <>
   <Header/>
   <Outlet/>
   <Footer/>
   </>
  );
}

export default App;
