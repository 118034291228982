import { useEffect, useRef } from "react";

function FlightResult() {
    const ref = useRef(false)
    useEffect(()=>{
        if(!ref.current){
            ref.current = true
            var script = document.createElement("script")
            script.innerHTML = `$('.owl-carousel').owlCarousel({
                loop:true,
                margin:40,
                nav:true,
                autoplay:1000,
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:3
                    },
                    1000:{
                        items:6
                    }
                }
            })`
            document.body.appendChild(script)
        }
        
        
    },[])
    return (<>
    <section id="show_flights" >
			<div class="container">
				<div class="col-md-12">
					<div class="row">
						<div class="col-md-8 col-sm-8">
							<div class="cheapest_flights">
								<h4>Cheapest Flights</h4>
								<div class="owl-carousel owl-theme">
				                  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
								  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
								  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
								  <div class="item">
										<div class="heading">IndiGo</div>
					                  	<img src="img/flight1.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  <div class="item">
										<div class="heading">SpiceJet</div>
					                  	<img src="img/flight2.gif" alt=""/>
					                  	<div class="price">
										<span class="currency"></span>
										<i class="INR"></i> <span class="amt">50,529</span>
										</div>
								  </div>
				                  </div>
							</div>
						</div>
						<div class="col-md-4 col-sm-4">
							<div class="flightPromoCont"><div> <div class="u_inlineblk u_font12">Get upto <span class="currency INR u_clViaRed"></span> <b class="amt u_clViaRed u_font16">7000</b> Off* on International flights</div><div class="u_textL u_fontW400 u_marT8 u_font12">Use code <b class="u_clViaGreen u_txtDecoNone u_padR10 u_padL5">VIAINTL</b></div><div class="u_textL u_fontW300 u_font12 u_marT8 u_marB5"><a href="#" target="_blank"> <span class="u_textDecoUL u_textCapitalize">terms apply</span></a></div></div></div>
						</div>
					</div>
				</div>	
				<div class="clearfix"></div>
				<div class="col-md-12">	
					<div class="flight_listing_start">
						<div class="box">
                                <div class="row">
                                    <div class="col-md-7 col-sm-7 f1">
                                        <div class="row">
                                            <div class="flightco col-md-3 col-sm-3 col-xs-12 text-center"  style={{paddingTop:"0",margin:"0"}}>
                                                <img src="img/UK.gif" alt="UK"/>
                                                <p style={{margin:"5px 0px 0px"}}>Vistara</p>
                                                <span>UK-923</span>
                                                <p style={{margin:"5px 0px 0px"}}>Class: Z</p>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">Departure</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>06:20</p>
                                                <h4 class="marg0">Delhi</h4>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12">
                                                <div class="duration">
                                                    <h4>2h :0m</h4>
                                					<b>non-stop</b>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 arrive text-center">
                                                <h4 class="marg0">Arrival</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>08:20</p>
                                                <h4 class="marg0">Mumbai</h4>
                                            </div>

                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                    <div class="col-md-5 col-sm-5 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4">
                                                <h6>
                                                    One way flights <b>non-stop</b>
                                                </h6>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
				                                <span style={{color:"#fb4c4c"}}>1 seats left</span>
				                                <span><a href="#">Flight Details</a></span>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
                                                <h4><span style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                            <p>
			                            	<span class="one-ref2" style={{lineHeight:"2.8"}}>Non-Refundable</span>
					                    	<button type="submit" class="btn text-center btn-danger book round center-block pull-right">BOOK NOW</button>
					                		</p>
                                            </div>
                                        </div>                                        
		                			</div>
		                 	</div>
                    	</div>
					</div>

					<div class="flight_listing_start">
						<div class="box">
                                <div class="row">
                                    <div class="col-md-7 col-sm-7 f1">
                                        <div class="row">
                                            <div class="flightco col-md-3 col-sm-3 col-xs-12 text-center"  style={{paddingTop:"0",margin:"0"}}>
                                                <img src="img/UK.gif" alt="UK"/>
                                                <p style={{margin:"5px 0px 0px"}}>Vistara</p>
                                                <span>UK-923</span>
                                                <p style={{margin:"5px 0px 0px"}}>Class: Z</p>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">Departure</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>06:20</p>
                                                <h4 class="marg0">Delhi</h4>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12">
                                                <div class="duration">
                                                    <h4>2h :0m</h4>
                                					<b>non-stop</b>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 arrive text-center">
                                                <h4 class="marg0">Arrival</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>08:20</p>
                                                <h4 class="marg0">Mumbai</h4>
                                            </div>

                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                    <div class="col-md-5 col-sm-5 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4">
                                                <h6>
                                                    One way flights <b>non-stop</b>
                                                </h6>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
				                                <span style={{color:"#fb4c4c"}}>1 seats left</span>
				                                <span><a href="#">Flight Details</a></span>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
                                                <h4><span style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                            <p>
			                            	<span class="one-ref2" style={{lineHeight:"2.8"}}>Non-Refundable</span>
					                    	<button type="submit" class="btn text-center btn-danger book round center-block pull-right">BOOK NOW</button>
					                		</p>
                                            </div>
                                        </div>                                        
		                			</div>
		                 	</div>
                    	</div>
					</div>

					<div class="flight_listing_start">
						<div class="box">
                                <div class="row">
                                    <div class="col-md-7 col-sm-7 f1">
                                        <div class="row">
                                            <div class="flightco col-md-3 col-sm-3 col-xs-12 text-center"  style={{paddingTop:"0",margin:"0"}}>
                                                <img src="img/UK.gif" alt="UK"/>
                                                <p style={{margin:"5px 0px 0px"}}>Vistara</p>
                                                <span>UK-923</span>
                                                <p style={{margin:"5px 0px 0px"}}>Class: Z</p>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">Departure</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>06:20</p>
                                                <h4 class="marg0">Delhi</h4>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12">
                                                <div class="duration">
                                                    <h4>2h :0m</h4>
                                					<b>non-stop</b>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 arrive text-center">
                                                <h4 class="marg0">Arrival</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>08:20</p>
                                                <h4 class="marg0">Mumbai</h4>
                                            </div>

                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                    <div class="col-md-5 col-sm-5 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4">
                                                <h6>
                                                    One way flights <b>non-stop</b>
                                                </h6>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
				                                <span style={{color:"#fb4c4c"}}>1 seats left</span>
				                                <span><a href="#">Flight Details</a></span>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
                                                <h4><span style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                            <p>
			                            	<span class="one-ref2" style={{lineHeight:"2.8"}}>Non-Refundable</span>
					                    	<button type="submit" class="btn text-center btn-danger book round center-block pull-right">BOOK NOW</button>
					                		</p>
                                            </div>
                                        </div>                                        
		                			</div>
		                 	</div>
                    	</div>
					</div>

					<div class="flight_listing_start">
						<div class="box">
                                <div class="row">
                                    <div class="col-md-7 col-sm-7 f1">
                                        <div class="row">
                                            <div class="flightco col-md-3 col-sm-3 col-xs-12 text-center"  style={{paddingTop:"0",margin:"0"}}>
                                                <img src="img/UK.gif" alt="UK"/>
                                                <p style={{margin:"5px 0px 0px"}}>Vistara</p>
                                                <span>UK-923</span>
                                                <p style={{margin:"5px 0px 0px"}}>Class: Z</p>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">Departure</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>06:20</p>
                                                <h4 class="marg0">Delhi</h4>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12">
                                                <div class="duration">
                                                    <h4>2h :0m</h4>
                                					<b>non-stop</b>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 arrive text-center">
                                                <h4 class="marg0">Arrival</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>08:20</p>
                                                <h4 class="marg0">Mumbai</h4>
                                            </div>

                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                    <div class="col-md-5 col-sm-5 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4">
                                                <h6>
                                                    One way flights <b>non-stop</b>
                                                </h6>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
				                                <span style={{color:"#fb4c4c"}}>1 seats left</span>
				                                <span><a href="#">Flight Details</a></span>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
                                                <h4><span style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                            <p>
			                            	<span class="one-ref2" style={{lineHeight:"2.8"}}>Non-Refundable</span>
					                    	<button type="submit" class="btn text-center btn-danger book round center-block pull-right">BOOK NOW</button>
					                		</p>
                                            </div>
                                        </div>                                        
		                			</div>
		                 	</div>
                    	</div>
					</div>

					<div class="flight_listing_start">
						<div class="box">
                                <div class="row">
                                    <div class="col-md-7 col-sm-7 f1">
                                        <div class="row">
                                            <div class="flightco col-md-3 col-sm-3 col-xs-12 text-center"  style={{paddingTop:"0",margin:"0"}}>
                                                <img src="img/UK.gif" alt="UK"/>
                                                <p style={{margin:"5px 0px 0px"}}>Vistara</p>
                                                <span>UK-923</span>
                                                <p style={{margin:"5px 0px 0px"}}>Class: Z</p>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">Departure</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>06:20</p>
                                                <h4 class="marg0">Delhi</h4>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12">
                                                <div class="duration">
                                                    <h4>2h :0m</h4>
                                					<b>non-stop</b>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 arrive text-center">
                                                <h4 class="marg0">Arrival</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>08:20</p>
                                                <h4 class="marg0">Mumbai</h4>
                                            </div>

                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                    <div class="col-md-5 col-sm-5 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4">
                                                <h6>
                                                    One way flights <b>non-stop</b>
                                                </h6>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
				                                <span style={{color:"#fb4c4c"}}>1 seats left</span>
				                                <span><a href="#">Flight Details</a></span>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
                                                <h4><span style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                            <p>
			                            	<span class="one-ref2" style={{lineHeight:"2.8"}}>Non-Refundable</span>
					                    	<button type="submit" class="btn text-center btn-danger book round center-block pull-right">BOOK NOW</button>
					                		</p>
                                            </div>
                                        </div>                                        
		                			</div>
		                 	</div>
                    	</div>
					</div>

					<div class="flight_listing_start">
						<div class="box">
                                <div class="row">
                                    <div class="col-md-7 col-sm-7 f1">
                                        <div class="row">
                                            <div class="flightco col-md-3 col-sm-3 col-xs-12 text-center"  style={{paddingTop:"0",margin:"0"}}>
                                                <img src="img/UK.gif" alt="UK"/>
                                                <p style={{margin:"5px 0px 0px"}}>Vistara</p>
                                                <span>UK-923</span>
                                                <p style={{margin:"5px 0px 0px"}}>Class: Z</p>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">Departure</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>06:20</p>
                                                <h4 class="marg0">Delhi</h4>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12">
                                                <div class="duration">
                                                    <h4>2h :0m</h4>
                                					<b>non-stop</b>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 arrive text-center">
                                                <h4 class="marg0">Arrival</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>08:20</p>
                                                <h4 class="marg0">Mumbai</h4>
                                            </div>

                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                    <div class="col-md-5 col-sm-5 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4">
                                                <h6>
                                                    One way flights <b>non-stop</b>
                                                </h6>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
				                                <span style={{color:"#fb4c4c"}}>1 seats left</span>
				                                <span><a href="#">Flight Details</a></span>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
                                                <h4><span style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                            <p>
			                            	<span class="one-ref2" style={{lineHeight:"2.8"}}>Non-Refundable</span>
					                    	<button type="submit" class="btn text-center btn-danger book round center-block pull-right">BOOK NOW</button>
					                		</p>
                                            </div>
                                        </div>                                        
		                			</div>
		                 	</div>
                    	</div>
					</div>

					<div class="flight_listing_start">
						<div class="box">
                                <div class="row">
                                    <div class="col-md-7 col-sm-7 f1">
                                        <div class="row">
                                            <div class="flightco col-md-3 col-sm-3 col-xs-12 text-center"  style={{paddingTop:"0",margin:"0"}}>
                                                <img src="img/UK.gif" alt="UK"/>
                                                <p style={{margin:"5px 0px 0px"}}>Vistara</p>
                                                <span>UK-923</span>
                                                <p style={{margin:"5px 0px 0px"}}>Class: Z</p>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 depart text-center">

                                                <h4 class="marg0">Departure</h4>
                                                <img src="img/takein.jpg" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>06:20</p>
                                                <h4 class="marg0">Delhi</h4>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12">
                                                <div class="duration">
                                                    <h4>2h :0m</h4>
                                					<b>non-stop</b>
                                                </div>
                                            </div>

                                            <div class="col-md-3 col-sm-3 col-xs-12 arrive text-center">
                                                <h4 class="marg0">Arrival</h4>
                                                <img src="img/takeoff.png" alt="takeoff"/>
                                                <p style={{marginBottom:"5px"}}>Tue 14 May</p>
                                                <p>08:20</p>
                                                <h4 class="marg0">Mumbai</h4>
                                            </div>

                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                    <div class="col-md-5 col-sm-5 f2 text-center booknow">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4">
                                                <h6>
                                                    One way flights <b>non-stop</b>
                                                </h6>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
				                                <span style={{color:"#fb4c4c"}}>1 seats left</span>
				                                <span><a href="#">Flight Details</a></span>
                                            </div>
                                            <div class="col-md-4 col-sm-4">
                                                <h4><span style={{fontSize:"14px"}}>Rs. 29199</span></h4>
                                            <p>
			                            	<span class="one-ref2" style={{lineHeight:"2.8"}}>Non-Refundable</span>
					                    	<button type="submit" class="btn text-center btn-danger book round center-block pull-right">BOOK NOW</button>
					                		</p>
                                            </div>
                                        </div>                                        
		                			</div>
		                 	</div>
                    	</div>
					</div>
					




				</div>		
			</div>
		</section>
    </>);
}

export default FlightResult;