import FlightResult from "./hotelSearch";
import ResultFilter from "./resultFilter";
import Filter from "./searchfilter";

function Hotel() {
    return (<>
    <Filter/>
    <ResultFilter/>
    <FlightResult/>
    </>);
}

export default Hotel;