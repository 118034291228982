function AdultDeatil() {
    return (<>
    <div class="details_information">
    							<h3>Travel Details</h3>
    							     <div class="pay-sign-box">
                                        <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <h4>Adult</h4>
                                        <div class="row">
                                            <div class="form-group col-md-2">
                                                <label>Adult1 *</label>
                                                <select class="form-control">
                                                    <option value="93" data-country-iso="afg" data-country-name="Afghanistan">
                                                        Mr.
                                                    </option>
                                                    <option value="355" data-country-iso="alb" data-country-name="Albania">
                                                        Mrs
                                                    </option>
                                                    <option value="213" data-country-iso="dza" data-country-name="Algeria">
                                                        Ms
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>First Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>Last Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                        </div>
                                     </div>
                                     <div class="pay-sign-box mt-3">
                                        <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <h4>Adult</h4>
                                        <div class="row">
                                            <div class="form-group col-md-2">
                                                <label>Adult1 *</label>
                                                <select class="form-control">
                                                    <option value="93" data-country-iso="afg" data-country-name="Afghanistan">
                                                        Mr.
                                                    </option>
                                                    <option value="355" data-country-iso="alb" data-country-name="Albania">
                                                        Mrs
                                                    </option>
                                                    <option value="213" data-country-iso="dza" data-country-name="Algeria">
                                                        Ms
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>First Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>Last Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                   
                                        </div>
                                     </div>
                                     <div class="pay-sign-box mt-3" >
                                        <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <h4>Adult</h4>
                                        <div class="row">
                                            <div class="form-group col-md-2">
                                                <label>Adult1 *</label>
                                                <select class="form-control">
                                                    <option value="93" data-country-iso="afg" data-country-name="Afghanistan">
                                                        Mr.
                                                    </option>
                                                    <option value="355" data-country-iso="alb" data-country-name="Albania">
                                                        Mrs
                                                    </option>
                                                    <option value="213" data-country-iso="dza" data-country-name="Algeria">
                                                        Ms
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>First Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>Last Name *</label>
                                                <input class="form-control" type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                        </div>
                                     </div>
    </div>
    </>)
}

export default AdultDeatil;