import { useEffect, useRef } from "react"

function AgentLogin() {
    const ref = useRef(false)
    useEffect(()=>{
        if(!ref.current){
            ref.current = true
            var script = document.createElement("script")
            script.innerHTML = `$('.owl-carousel').owlCarousel({
                loop:true,
                margin:40,
                nav:true,
                autoplay:1000,
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:3
                    },
                    1000:{
                        items:6
                    }
                }
            })`
            document.body.appendChild(script)
        }
        
        
    },[])
    return (
        <>
        <section id="agent_login">
	<div className="banner_login">
		<div id="myCarousel" className="carousel slide" data-ride="carousel">
		 
		  <ol className="carousel-indicators">
		    <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
		    <li data-target="#myCarousel" data-slide-to="1"></li>
		    <li data-target="#myCarousel" data-slide-to="2"></li>
		  </ol>

  			<div className="col-md-4 sec-sign">
	    <div className="row marg0">
	        <div className="col-md-12">
	            <h3>Sign In</h3>
	            <form>
				  <div className="form-group">
				    <label for="exampleInputEmail1">Email address</label>
				    <input type="email" className="form-control" placeholder="Enter email"/>
				  </div>
				  <div className="form-group">
				    <label for="exampleInputPassword1">Password</label>
				    <input type="password" className="form-control" placeholder="Password"/>
				  </div>
				  <div className="form-check">
				    <input type="checkbox" className="form-check-input"/>
				    <label className="form-check-label" for="exampleCheck1">Check me out</label>
				    <a href="#" className="forgot-pass">Forgot Password?</a>
				  </div>
				  <button type="submit" className="btn btn-primary">Submit</button>
				  <p>No account? <a href="#">Sign Up</a></p>
				</form>
	        </div>
	    </div>
  			</div>

	  <div className="carousel-inner">
	    <div className="item active">
	      <img src="img/agent-slider-01.jpg" alt="Geturtrip" style={{width:"100%"}}/>
	      <div className="carousel-caption">
		        <div className="col-md-8 sec-bg">
		                <div className="sec-img-text">
		                    <div className="row">
		                        <div className="col-md-12">
		                            <img src="img/logo.jpg" className="img-responsive" alt="geturtrip"/>
		                            <h1><strong>Grow your business with Us</strong></h1>
		                            <p>"Welcome to Geturtrip - Your complete guide to creation, management and execution of tours. Track leads, create itineraries and generate invoices at the click of a button. geturtrip organizes your data systematically for smooth running of operations."</p>
		                            <p className="information-start">
		                                <span><img src="img/support1.png" alt="support"/> &nbsp; +91 9014592771,</span><span><img src="img/support2.png" alt="support"/>In Case of Emergency  &nbsp; +91 9000140005,</span> 
		                                <span><img src="img/support3.png" alt="support"/> &nbsp; info@Seturtrip.com</span>
		                            </p>
		                        </div>
		                    </div>
		                </div>
		        </div>
	      </div>
	    </div>

	    <div className="item">
	      <img src="img/agent-slider-02.jpg" alt="Geturtrip" style={{width:"100%"}}/>
	      <div className="carousel-caption">
		        <div className="col-md-8 sec-bg">
		                <div className="sec-img-text">
		                    <div className="row">
		                        <div className="col-md-12">
		                            <img src="img/logo.jpg" className="img-responsive" alt="geturtrip"/>
		                            <h1><strong>Grow your business with Us</strong></h1>
		                            <p>"Welcome to Geturtrip - Your complete guide to creation, management and execution of tours. Track leads, create itineraries and generate invoices at the click of a button. geturtrip organizes your data systematically for smooth running of operations."</p>
		                            <p className="information-start">
		                                <span><img src="img/support1.png" alt="support"/> &nbsp; +91 9014592771,</span><span><img src="img/support2.png" alt="support"/>In Case of Emergency  &nbsp; +91 9000140005,</span> 
		                                <span><img src="img/support3.png" alt="support"/> &nbsp; info@Seturtrip.com</span>
		                            </p>
		                        </div>
		                    </div>
		                </div>
		        </div>
	      </div>
	    </div>

	    <div className="item">
	      <img src="img/agent-slider-03.jpg" alt="Geturtrip" style={{width:"100%"}}/>
	      <div className="carousel-caption">
		        <div className="col-md-8 sec-bg">
		                <div className="sec-img-text">
		                    <div className="row">
		                        <div className="col-md-12">
		                            <img src="img/logo.jpg" className="img-responsive" alt="geturtrip"/>
		                            <h1><strong>Grow your business with Us</strong></h1>
		                            <p>"Welcome to Geturtrip - Your complete guide to creation, management and execution of tours. Track leads, create itineraries and generate invoices at the click of a button. geturtrip organizes your data systematically for smooth running of operations."</p>
		                            <p className="information-start">
		                                <span><img src="img/support1.png" alt="support"/> &nbsp; +91 9014592771,</span><span><img src="img/support2.png" alt="support"/>In Case of Emergency  &nbsp; +91 9000140005,</span> 
		                                <span><img src="img/support3.png" alt="support"/> &nbsp; info@Seturtrip.com</span>
		                            </p>
		                        </div>
		                    </div>
		                </div>
		        </div>
	      </div>
	    </div>
	  </div>

		</div>
	</div>
</section>

    <section id="gallery-slidermain" className="new-agent-page">
    	 <div className="container">
            <div className="container">
                  <div className="owl-carousel owl-theme">
                  <div className="item"><img src="img/gallery/1.jpg" alt=""/><h4>Dubai</h4></div>
                  <div className="item"><img src="img/gallery/2.jpg" alt=""/><h4>Goa</h4></div>
                  <div className="item"><img src="img/gallery/3.jpg" alt=""/><h4>Kashmir</h4></div>
                  <div className="item"><img src="img/gallery/4.jpg" alt=""/><h4>Kerala</h4></div>
                  <div className="item"><img src="img/gallery/5.jpg" alt=""/><h4>Singapore</h4></div>
                  <div className="item"><img src="img/gallery/6.jpg" alt=""/><h4>Thailand</h4></div>
                  <div className="item"><img src="img/gallery/7.jpg" alt=""/><h4>Kashmir</h4></div>
                  </div>
           </div>
   	    </div>
    </section>
        </>
    );
}

export default AgentLogin;