import './style.css'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { Chip } from '@mui/material';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MoreDetails from './moreDetailsPopUp';
import '../../vars.css'

function FlightList() {
    
    return (<>
    <div class="flight_listing_start">
              <div class="box">
                <div class="row">
                  <div class="col-md-7 col-sm-7 f1">
                    <div class="row">
                      <div class="flightco col-md-3 col-sm-3 col-xs-12 text-center" style={{ paddingTop: "0", margin: "0",display: "flex",alignItems: "center",marginBottom:"15px"}}>
                        <img src="img/UK.gif" alt="UK" />
                        <div className='flightcoText'>
                        <p style={{ margin: "5px 0px 0px" }}>Vistara</p>
                        <span>UK-923</span>
                        </div>
                        
                      </div>

                      <div class="col-md-3 col-sm-3 col-xs-12 col-4 depart text-center">
                      <h4 class="marg0"><FlightTakeoffIcon/> Delhi</h4>
                        
                        <p style={{ marginBottom: "5px 0px 0px" }}>06:20 </p>
                      
                        
                      </div>

                      <div class="col-md-3 col-sm-3 col-xs-12  col-4">
                        <div class="duration">
                          <h4><AirplanemodeActiveIcon sx={{transform:"rotate(90deg)"}}/> 2h :0m</h4>
                          <p>Non Stop</p>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-3 col-xs-12 col-4 arrive text-center">
                      <h4 class="marg0"><FlightLandIcon /> Mumbai</h4>
                        
                        <p style={{ marginBottom: "5px 0px 0px" }}>08:20</p>
                        
                        
                      </div>

                      <div class="clearfix"></div>
                    </div>
                  </div>
<hr className='m-hr' />
                  <div class="col-md-5 col-sm-5 f2 text-center booknow ">
                    <div class="row">
                      <div class="col-md-2 col-sm-2  col-6 nopadding">
                        <h6>
                      <b>Non Stop</b>
                        </h6>
                      </div>
                      <div class="col-md-4 col-sm-4   col-6" style={{lineHeight:"140%"}}>
                        <span style={{ color: "var(--color-theme-primary-color)" }}>1 seats left</span><br/>
                        <span style={{marginBottom:"15px",display:"inline-block"}}>
                          <a href="#" style={{color: "var(--color-theme-secondary-color)"}}>Flight Details</a>
                        </span>
                      </div>
                      <hr  className='m-hr'  />
                      <div class="col-md-6 col-sm-6">
                      <div style={{display: "flex",alignItems: "start"}}>
                      <h4>
                          <span style={{ fontSize: "18px" ,color:"var(--color-flight-price)" }}>Rs. 29199</span>
                          <Chip variant="outlined" size={"small"} label={"Non-Refundable"} sx={{"& .MuiChip-label": {color: "#000",fontSize:"9px !important" }}}/>
                        </h4>
                        
                        <p style={{paddingLeft:"5px"}}>
                        
                          <button type="submit" class="btn text-center btn-danger book round center-block pull-right" style={{height:"40px",width:"100%"}}>BOOK NOW</button>
                          <MoreDetails/>
                        </p>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
    </>);
}

export default FlightList;