function Filter() {
    return (<>
    <section id="start_body_area">
        	<div className="container">
        		<div className="col-md-12">
        			<div className="main_search_form-area">
        				<form>
								  <div className="form-group" id="main_inline_checkbox">
								  	<label><input type="checkbox"/>One way</label>
								  	<label><input type="checkbox"/>Round trip</label>
								   <input type="text" name="" className="" placeholder="From : Type City"/>
								  </div>

									  <div className="form-group" id="main_inline_checkbox">
									  	<label><input type="checkbox"/>Special Round Trip</label>
									  	<label><input type="checkbox"/>Multi City</label>
									    <input type="text" name="" className="" placeholder="To : Type City"/>
									  </div>

									  <div className="form-group">
									  	<label>Depart:</label>
									  	<input type="text" id="datepicker" className="new-form-cls" placeholder="dd/mm/yyyy..."/>
									  </div>

										<div className="form-group">
										  	<label>Return:</label>
										  	<input type="text" id="datepicker2" className="new-form-cls" placeholder="dd/mm/yyyy..."/>
									  	</div>

								<div className="form-group">
									<div className="multiselect">
	        							<div className="button-group">
								        	<button type="button" className="btn btn-default" data-toggle="dropdown">Economy<span className="caret"></span></button>
												<ul className="dropdown-menu">
												  	<li>
													  	<a href="#">
													  		<div className="form-group">
													  			<label>Adults:</label>
													  			<input type="number" name="" className="" placeholder="0"/>
															</div>
														</a>
												  	</li>
													<li>
														<a href="#">
															<div className="form-group">
																<label>Childs(2-12):</label>
																<input type="number" name="" className="" placeholder="0"/>
															</div>
														</a>
													</li>
													<li>
														<a href="#">
															<div className="form-group">
															  	<label>Infant(0-2):</label>
															    <input type="number" name="" className="" placeholder="0"/>
															</div>
														</a>
													</li>
												</ul>
										  </div>  
					   				</div>
								  </div>

										  <div className="form-group">
										    <select name="carlist" form="carform" className="main_select">
											  <option value="Select">Economy</option>
											  <option value="saab">Saab</option>
											  <option value="opel">Opel</option>
											  <option value="audi">Audi</option>
											</select>	
										  </div>

										  <div className="form-group" id="div_biger">
											<button type="submit" className="btn btn-default submit_btns">Search</button>
										  </div>
								</form>
        			</div>
        		</div>
        	</div>
        </section>
    </>);
}

export default Filter