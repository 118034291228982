import { Card, Grid } from "@mui/material";
import { useEffect, useRef } from "react";
import FlightList from "../../component/flightList/flightList";

function FlightResult() {
  const ref = useRef(false);
  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      var script = document.createElement("script");
      script.innerHTML = `$('.owl-carousel').owlCarousel({
                loop:true,
                margin:40,
                nav:true,
                autoplay:1000,
                responsive:{
                    0:{
                        items:3
                    },
                    600:{
                        items:3
                    },
                    1000:{
                        items:6
                    }
                }
            })`;
      document.body.appendChild(script);
    }
  }, []);
  return (
    <>
      <section id="show_flights">
        <div class="container">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-8 col-sm-8">
                <div class="cheapest_flights">
                  <h4>Cheapest Flights</h4>
                  <div class="owl-carousel owl-theme">
                    <div class="item">
                      <div class="heading">IndiGo</div>
                      <img src="img/flight1.gif" alt="" />
                      <div class="price">
                        <span class="currency"></span>
                        <i class="INR"></i> <span class="amt">50,529</span>
                      </div>
                    </div>
                    <div class="item">
                      <div class="heading">SpiceJet</div>
                      <img src="img/flight2.gif" alt="" />
                      <div class="price">
                        <span class="currency"></span>
                        <i class="INR"></i> <span class="amt">50,529</span>
                      </div>
                    </div>
                    <div class="item">
                      <div class="heading">IndiGo</div>
                      <img src="img/flight1.gif" alt="" />
                      <div class="price">
                        <span class="currency"></span>
                        <i class="INR"></i> <span class="amt">50,529</span>
                      </div>
                    </div>
                    <div class="item">
                      <div class="heading">SpiceJet</div>
                      <img src="img/flight2.gif" alt="" />
                      <div class="price">
                        <span class="currency"></span>
                        <i class="INR"></i> <span class="amt">50,529</span>
                      </div>
                    </div>
                    <div class="item">
                      <div class="heading">IndiGo</div>
                      <img src="img/flight1.gif" alt="" />
                      <div class="price">
                        <span class="currency"></span>
                        <i class="INR"></i> <span class="amt">50,529</span>
                      </div>
                    </div>
                    <div class="item">
                      <div class="heading">SpiceJet</div>
                      <img src="img/flight2.gif" alt="" />
                      <div class="price">
                        <span class="currency"></span>
                        <i class="INR"></i> <span class="amt">50,529</span>
                      </div>
                    </div>
                    <div class="item">
                      <div class="heading">IndiGo</div>
                      <img src="img/flight1.gif" alt="" />
                      <div class="price">
                        <span class="currency"></span>
                        <i class="INR"></i> <span class="amt">50,529</span>
                      </div>
                    </div>
                    <div class="item">
                      <div class="heading">SpiceJet</div>
                      <img src="img/flight2.gif" alt="" />
                      <div class="price">
                        <span class="currency"></span>
                        <i class="INR"></i> <span class="amt">50,529</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="flightPromoCont">
                  <div>
                    {" "}
                    <div class="u_inlineblk u_font12">
                      Get upto <span class="currency INR u_clViaRed"></span>{" "}
                      <b class="amt u_clViaRed u_font16">7000</b> Off* on
                      International flights
                    </div>
                    <div class="u_textL u_fontW400 u_marT8 u_font12">
                      Use code{" "}
                      <b class="u_clViaGreen u_txtDecoNone u_padR10 u_padL5">
                        VIAINTL
                      </b>
                    </div>
                    <div class="u_textL u_fontW300 u_font12 u_marT8 u_marB5">
                      <a href="#" target="_blank">
                        {" "}
                        <span class="u_textDecoUL u_textCapitalize">
                          terms apply
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-md-12" id="one_why_flight">
            
            
            <FlightList/>
            <FlightList/>
            <FlightList/>
            <FlightList/>
            <FlightList/>

            
          </div>
        </div>
        <br/>
      </section>
    </>
  );
}

export default FlightResult;
