import FlightResult from "./flightSearch";
import ResultFilter from "./resultFilter";
import Filter from "./searchfilter";

function SpecialRoundTrip() {
    return (<>
    <Filter/>
    <ResultFilter/>
    <FlightResult/>
    </>);
}

export default SpecialRoundTrip;